import React, { FC, ReactNode, memo } from 'react';

import { Root } from './sidebar.styles';

interface SidebarProps {
  children?: ReactNode;
}

export const Sidebar: FC<SidebarProps> = memo((props) => {
  const { children } = props;

  return <Root>{children}</Root>;
});
