import styled from 'styled-components';

import { colors, mixins, typography } from '../theme';

export const Root = styled.div`
  background: ${colors.accent.yellow};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  ${typography.bold16};
  line-height: 1;
  ${mixins.flex({
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
  })};
`;
