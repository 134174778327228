import styled from 'styled-components';

import FileUploader from '@ui-kit/file-uploader';
import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
`;

export const InputWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 24px;
`;

export const Label = styled.div`
  margin-right: 12px;
  width: 30%;
  text-align: left;

  ${typography.regular16};
`;

export const Input = styled.input`
  ${typography.regular14};
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.background.grayExtraLight};
  background: none;
  padding: 4px;
  box-sizing: border-box;
  flex: 1;
  transition: all ease ${animations.duration};

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;

export const Text = styled.textarea`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.background.grayExtraLight};
  background: none;
  padding: 4px;
  box-sizing: border-box;
  flex: 1;
  min-width: 90px;
  max-width: 270px;
  min-height: 80px;
  transition: all ease ${animations.duration};

  ${typography.regular14};

  transition: all ease ${animations.duration};

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;

export const StyledFileUploader = styled(FileUploader)`
  flex: 1;
  & > div {
    border-color: ${colors.background.grayExtraLight};
    color: ${colors.text.gray};

    &:hover {
      color: ${colors.text.gray};
      border-color: ${colors.background.grayDark};
    }
  }
`;

export const Actions = styled.div`
  ${mixins.flex({
    horizontalAlignment: 'center',
  })};
`;
