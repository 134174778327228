import React, { FC, ReactNode, memo, useCallback } from 'react';

import Icons from '../icons';
import {
  Root,
  StepContent,
  StepIcon,
  StepItem,
  StepLabel,
  StepText,
  StepValue,
} from './stepper.styles';

export interface Step {
  label: string;
  value?: ReactNode;
  text?: ReactNode;
}

interface StepperProps {
  steps: Step[];
  currentStep?: number;
  onClick?: (step: number) => void;
  checkViewedSteps?: boolean;
}

export const Stepper: FC<StepperProps> = memo((props) => {
  const { steps, currentStep = 1, onClick, checkViewedSteps = true } = props;

  const handleClick = useCallback(
    (step: number) => {
      if (onClick) {
        onClick(step);
      }
    },
    [onClick]
  );

  const stepIndex = currentStep - 1;

  const renderIcon = (currIndex: number) => {
    if (currIndex > stepIndex) {
      return <Icons.StepEmpty size={18} />;
    }
    if (checkViewedSteps && currIndex < stepIndex) {
      return <Icons.StepDone size={18} />;
    }
    if (!checkViewedSteps && currIndex < stepIndex) {
      return <Icons.StepEmpty size={18} />;
    }
    if (currIndex === stepIndex) {
      return <Icons.StepCurrent size={18} />;
    }
  };

  return (
    <Root>
      {steps.map(({ label, value, text }, i) => (
        <StepItem
          key={`step-${i}-${value}}`}
          hasAction={!!onClick}
          onClick={() => handleClick(i + 1)}
        >
          <StepIcon>{renderIcon(i)}</StepIcon>
          <StepContent>
            <StepLabel>{label}</StepLabel>
            {i === stepIndex && value && <StepValue>{value}</StepValue>}
            {text && <StepText>{text}</StepText>}
          </StepContent>
        </StepItem>
      ))}
    </Root>
  );
});
