import styled from 'styled-components';

import { colors } from '@src/ui-kit/theme';
import { mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
  height: 100%;

  padding-left: 90px;
  padding-right: 90px;
`;

export const OneEye = styled.img`
  position: absolute;
  bottom: 18px;
  left: 24px;
  width: 30px;
  height: 30px;
`;

export const TwoEye = styled.div`
  display: flex;
  gap: 15px;
`;

export const ThreeEye = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 23px;
  width: 100%;
`;

export const Eye = styled.img`
  width: 30px;
  height: 30px;
`;

export const PayInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 43px;
`;

export const PayInfoAccept = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 43px;
`;

export const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

export const BoxValue = styled.div`
  min-width: 115px;
  height: 24px;
  border-radius: 6px;
  color: ${colors.background.grayDark};
  border: 1px solid ${colors.text.grayExtraLight};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemPayInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ItemPaymentInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-top: 14px;
`;

export const ItemPayInfoSumm = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 22px;
  margin-right: 10px;
`;

export const DopText = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 16px;
`;

export const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.background.grayDark};
`;

export const TextCheckBox = styled.p`
  margin: 0;
  width: 470px;
  font-weight: 300;
  font-size: 16px;
`;

export const BoxButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 13px;
`;

export const Ukassa = styled.img`
  margin-left: 8px;
`;
