import React, { FC, memo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Stepper from '@ui-kit/stepper';

import { Root } from './sidebar-faq.styles';

interface SidebarCreateArProps {}

export const SidebarFaq: FC<SidebarCreateArProps> = memo(() => {
  const { question } = useParams();
  const navigate = useNavigate();

  const currentStep = (question && Number(question)) || undefined;

  const handleStepChange = useCallback(
    (step: number) => {
      navigate(`/faq/${step}`);
    },
    [question]
  );

  return (
    <Root>
      <Stepper
        checkViewedSteps={false}
        steps={faqData}
        onClick={handleStepChange}
        currentStep={currentStep}
      />
    </Root>
  );
});

const faqData = [
  { label: 'Что такое AR, и чем полезна эта технология?' },
  { label: 'Что такое ARGOS, и для чего мне это?' },
  { label: 'Как подключить свое приложение к системе?' },
  { label: 'Подробнее про сценарий «Изображение»' },
  { label: 'Подробнее про сценарий «Объект»' },
  { label: 'Подробнее про сценарий «Локация»' },
  { label: 'Подробнее про сценарий «Часть тела»' },
];
