const a = ' ';

class Tagged<N extends string> {}

type Nominal<T, N extends string> = T & Tagged<N>;

export type TypographyNominal = Nominal<'TypographyType', string>;

const baseFontFamily = 'Open Sans, sans-serif';

const createTypography = (
  fontFamily: string,
  size: number,
  lineHeight: number,
  weight: string | number = 400,
  letterSpacing = 0
) =>
  `font: ${weight} ${size}px/${lineHeight}px ${fontFamily};
${letterSpacing ? `letter-spacing: ${letterSpacing}px;` : ''}
`.trim();

const createBaseTypography = createTypography.bind(null, baseFontFamily);

const adaptive = (
  desktop: string,
  tablet?: string,
  mobile?: string
): TypographyNominal =>
  `
${desktop}
${`@media (max-width: 900px) {${tablet || desktop}}`}
${`@media (max-width: 600px) {${mobile || tablet || desktop}}`}
`.trim() as TypographyNominal;

const types = {
  bold64: createBaseTypography(64, 72, 'bold'),
  medium64: createBaseTypography(64, 72, 500),
  regular64: createBaseTypography(64, 72, 'normal'),

  bold52: createBaseTypography(52, 64, 'bold'),
  medium52: createBaseTypography(52, 64, 500),
  regular52: createBaseTypography(52, 64, 'normal'),

  bold40: createBaseTypography(40, 48, 'bold'),
  medium40: createBaseTypography(40, 48, 500),
  regular40: createBaseTypography(40, 48, 'normal'),

  bold32: createBaseTypography(32, 40, 'bold'),
  medium32: createBaseTypography(32, 40, 500),
  regular32: createBaseTypography(32, 40, 'normal'),

  bold24: createBaseTypography(24, 32, 'bold'),
  medium24: createBaseTypography(24, 32, 500),
  regular24: createBaseTypography(24, 32, 'normal'),

  bold20: createBaseTypography(20, 28, 'bold'),
  medium20: createBaseTypography(20, 28, 500),
  regular20: createBaseTypography(20, 28, 'normal'),

  bold18: createBaseTypography(18, 22, 'bold'),
  medium18: createBaseTypography(18, 22, 500),
  regular18: createBaseTypography(18, 22, 'normal'),

  bold16: createBaseTypography(16, 24, 'bold'),
  medium16: createBaseTypography(16, 24, 500),
  regular16: createBaseTypography(16, 24, 'normal'),

  bold14: createBaseTypography(14, 20, 'bold'),
  medium14: createBaseTypography(14, 20, 500),
  regular14: createBaseTypography(14, 20, 'normal'),

  bold12: createBaseTypography(12, 20, 'bold'),
  medium12: createBaseTypography(12, 20, 500),
  regular12: createBaseTypography(12, 20, 'normal'),

  bold10: createBaseTypography(10, 20, 'bold'),
  medium10: createBaseTypography(10, 20, 500),
  regular10: createBaseTypography(10, 20, 'normal'),

  buttonM: createBaseTypography(14, 20, 500),
  buttonS: createBaseTypography(14, 20, 500),
  buttonL: createBaseTypography(14, 20, 500),

  input: createBaseTypography(14, 24, 'normal'),
  tableTitle: createBaseTypography(16, 24, 'bold'),
  tableText: createBaseTypography(16, 24, 'normal'),
};

export const typography = {
  ...types,

  head: {
    bold: adaptive(types.bold64, types.bold52, types.bold32),
    medium: adaptive(types.medium64, types.medium52, types.medium32),
    regular: adaptive(types.regular64, types.regular52, types.regular32),
  },

  subhead1: {
    bold: adaptive(types.bold32, types.bold32, types.bold32),
    medium: adaptive(types.medium32, types.medium32, types.medium32),
    regular: adaptive(types.regular32, types.regular32, types.regular32),
  },

  subhead2: {
    bold: adaptive(types.bold24, types.bold24, types.bold20),
    medium: adaptive(types.medium24, types.medium24, types.medium20),
    regular: adaptive(types.regular24, types.regular24, types.regular20),
  },

  body1: {
    bold: adaptive(types.bold20, types.bold20, types.bold18),
    medium: adaptive(types.medium20, types.medium20, types.medium18),
    regular: adaptive(types.regular20, types.regular20, types.regular18),
  },

  body2: {
    bold: adaptive(types.bold16),
    medium: adaptive(types.medium16),
    regular: adaptive(types.regular16),
  },

  caption1: {
    bold: adaptive(types.bold14),
    medium: adaptive(types.medium14),
    regular: adaptive(types.regular14),
  },

  caption2: {
    bold: adaptive(types.bold12),
    medium: adaptive(types.medium12),
    regular: adaptive(types.regular12),
  },

  caption3: {
    bold: adaptive(types.bold10),
    medium: adaptive(types.medium10),
    regular: adaptive(types.regular10),
  },

  buttons: {
    M: adaptive(createBaseTypography(14, 20, 500)),
    L: adaptive(createBaseTypography(16, 24, 500)),
  },

  inputs: {
    text: adaptive(createBaseTypography(14, 24, 'normal')),
  },
};
