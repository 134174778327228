import styled from 'styled-components';

import { mixins } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
`;

export const LoadingWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })}
`;
