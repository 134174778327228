import React, { FC, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import { ApplicationsCreators } from '@src/adapters/apps';
import { DataPackage } from '@src/adapters/data-item';
import { ConfirmationDialog, DataPackageDialog } from '@src/components/dialog';
import { AuthContext, MediaFiltersContext } from '@src/context';
import { ITEM_TYPE, SCENARIO } from '@src/core/types';
import { useDataPackage } from '@src/hooks';
import Accordion from '@ui-kit/accordion';
import Icons from '@ui-kit/icons';

import {
  IconsWrapper,
  Item,
  Root,
  StyledCheckbox,
} from './sidebar-media.styles';

interface SidebarCreateArProps {}

export const SidebarMedia: FC<SidebarCreateArProps> = memo((props) => {
  const { filters, onFilterChange } = useContext(MediaFiltersContext);
  const {
    dataPackageToDelete,
    setDataPackageToDelete,
    dataPackageToEdit,
    setDataPackageToEdit,
    resultMutateDelete,
    mutateEdit,
    resultMutateEdit,
    mutateDelete,
  } = useDataPackage();

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const handleConfirmDelete = () => {
    mutateDelete({
      variables: { dataPackageId: dataPackageToDelete?.id },
      onCompleted: () => {
        toast.success('Дата пакет успешно обновлен', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDataPackageToEdit(undefined);
        refetch();
      },
      onError: () => {
        toast.error('При удалении возникла ошибка', {
          position: toast.POSITION.TOP_RIGHT,
        });
        refetch();
      },
    });
    setDataPackageToDelete(undefined);
  };

  const { data, refetch } = useQuery<{
    applications: ApplicationsCreators[];
  }>(queries.app.list, {
    variables: {
      userId: user?.id,
    },
  });

  const items = useMemo(() => {
    if (!data) {
      return [];
    }

    const dataPackages = data.applications.reduce(
      (prev, app) => [
        ...prev,
        ...(app?.contentCreators[0]?.dataPackages || []),
      ],
      [] as DataPackage[]
    );

    return [
      {
        item: t('media.distribution'),
        panel: data.applications.map((app) => (
          <Item key={`distribution-item-${app.id}`}>
            <StyledCheckbox
              onChange={(value, name) => onFilterChange('app', value, name)}
              variant="accent"
              checked={filters.app.indexOf(app.id.toString()) > -1}
              label={app.name}
              name={app.id.toString()}
            />
          </Item>
        )),
      },
      {
        item: t('media.dataPackage'),
        panel: dataPackages.map((dataPackage) => {
          return (
            <Item key={`packages3-${dataPackage.id}`}>
              <StyledCheckbox
                variant="accent"
                onChange={(value, name) =>
                  onFilterChange('dataPackage', value, name)
                }
                checked={
                  filters.dataPackage.indexOf(dataPackage.id.toString()) > -1
                }
                label={dataPackage.name}
                name={dataPackage.id.toString()}
              />
              <IconsWrapper>
                <Icons.Edit
                  onClick={() => setDataPackageToEdit(dataPackage)}
                  size={20}
                />
                <Icons.Delete
                  onClick={() => setDataPackageToDelete(dataPackage)}
                  size={20}
                />
              </IconsWrapper>
            </Item>
          );
        }),
      },
      {
        item: t('media.arScenario'),
        panel: Object.values(SCENARIO).map((key) => (
          <Item key={`scenario-${key}`}>
            <StyledCheckbox
              variant="accent"
              onChange={(value, name) =>
                onFilterChange('scenario', value, name)
              }
              checked={filters.scenario.indexOf(key) > -1}
              label={t(`common.scenario.${key}`)}
              name={key}
            />
          </Item>
        )),
      },
      {
        item: t('media.content'),
        panel: Object.values(ITEM_TYPE).map((key) => (
          <Item key={`packages-${key}`}>
            <StyledCheckbox
              variant="accent"
              onChange={(value, name) =>
                onFilterChange('itemType', value, name)
              }
              checked={filters.itemType.indexOf(key) > -1}
              label={t(`common.type.${key}`)}
              name={key}
            />
          </Item>
        )),
      },
    ];
  }, [data, filters]);

  return (
    <Root>
      <Accordion items={items} />

      <ConfirmationDialog
        title="Вы действительно хотите удалить пакет данных?"
        isOpened={!!dataPackageToDelete}
        onConfirm={handleConfirmDelete}
        onClose={() => setDataPackageToDelete(undefined)}
        loading={resultMutateDelete.loading}
      />
      <DataPackageDialog
        mode="edit"
        dataPackage={dataPackageToEdit}
        isOpened={!!dataPackageToEdit}
        loading={resultMutateEdit.loading}
        onSubmit={(dataPackage, fileRef) => {
          mutateEdit({
            variables: {
              ...dataPackage,
              dataPackageId: dataPackageToEdit?.id,
              thumbnailFile:
                fileRef?.current?.files && fileRef?.current?.files[0],
            },
            onCompleted: () => {
              toast.success('Дата пакет успешно обновлен', {
                position: toast.POSITION.TOP_RIGHT,
              });
              setDataPackageToEdit(undefined);
              refetch();
            },
            onError: () => {
              toast.error('При редактировании возникла ошибка', {
                position: toast.POSITION.TOP_RIGHT,
              });
              refetch();
            },
          });
        }}
        onClose={() => setDataPackageToEdit(undefined)}
      />
    </Root>
  );
});
