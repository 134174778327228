import styled from 'styled-components';

import Icons from '@ui-kit/icons';
import SwitcherUI, { styles } from '@ui-kit/switcher';
import { animations, colors, media, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  margin: auto;
  ${mixins.flex({ horizontal: false, wrap: false })};
  min-height: 100%;
  padding-bottom: 48px;
  box-sizing: border-box;
`;

export const Title = styled.h1`
  ${typography.medium24};
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const Row = styled.div`
  ${mixins.flex({
    wrap: false,
    verticalAlignment: 'flex-start',
  })};
  margin-bottom: 20px;
`;

export const Label = styled.div`
  width: 50%;
  max-width: 50%;
  flex: 1;
  margin-top: 2px;
`;

export const Value = styled.div`
  max-width: 50%;
  ${typography.bold16};
`;

export const Switcher = styled(SwitcherUI)`
  min-height: 650px;
  ${media.forLaptopLarge} {
    min-height: 500px;
  }

  ${styles.ColumnContent} {
    padding: 0;
    overflow: auto;
    ${mixins.scrolls()};
  }
`;

export const LoadingRoot = styled.div`
  padding-top: 36px;
`;

export const SkeletonRowWrapper = styled.div`
  width: 100%;
  ${mixins.flex({})};
`;
export const SkeletonCol = styled.div<{ width?: string }>`
  padding: 8px;
  box-sizing: border-box;

  width: ${({ width }) => width};
`;

export const SubTitle = styled.div`
  ${typography.bold16};
  margin-bottom: 40px;
`;

export const SecondaryTitle = styled.div`
  padding: 48px 16px;
`;

export const HoverMenuWrapper = styled.div`
  ${mixins.flex({ horizontalAlignment: 'flex-end' })};

  & > div {
    opacity: 0;
    transition: all ease ${animations.duration};
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    flex-wrap: nowrap;
  }

  &:hover {
    & > div {
      opacity: 1;
    }
  }
`;

export const StyledMenuIcon = styled(Icons.Menu)`
  color: ${colors.background.grayExtraLight};
`;
