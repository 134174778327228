import styled from 'styled-components';

import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div``;
export const ActionsWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  ${mixins.flex({ horizontal: false })};

  a {
    ${typography.regular14};
    margin-top: 12px;
    text-decoration: underline;
    text-align: center;
    color: ${colors.text.grayExtraLight};
    transition: color ease ${animations.duration};

    &:hover {
      color: ${colors.text.gray};
    }
  }
`;
