import React, { FC, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CREATE_AR_ORDER, CreateArContext } from '@src/context';
import { CREATE_AR_FORM_MODE, CREATE_AR_STEP, SCENARIO } from '@src/core/types';
import Icons from '@ui-kit/icons';
import Stepper from '@ui-kit/stepper';

import {
  Item,
  ItemContent,
  ListSubLabel,
  Root,
  StepperWrapper,
  StyledIcon,
  SubTitle,
  Title,
} from './sidebar-create-ar.styles';

interface SidebarCreateArProps {}

export const getIcon = (scenery?: string) => {
  switch (scenery) {
    case SCENARIO.BY_IMAGE:
      return <Icons.ByImage size={14} />;
    case SCENARIO.IN_SPACE:
      return <Icons.BySpace size={14} />;
    case SCENARIO.BY_BODYPART:
      return <Icons.ByBodypart size={14} />;
    case SCENARIO.BY_GEO:
      return <Icons.ByGeo size={14} />;
    case SCENARIO.BY_OBJECT:
      return <Icons.ByObject size={14} />;
    default:
      return null;
  }
};

export const SidebarCreateAr: FC<SidebarCreateArProps> = memo((props) => {
  const { currentStepIndex, mode, dataItem, app } = useContext(CreateArContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const created = useMemo(() => {
    if (!dataItem?.createdAt) {
      return;
    }

    const date = new Date(dataItem.createdAt);

    return date.toLocaleDateString('ru-RU');
  }, [dataItem]);

  return (
    <Root>
      {mode === CREATE_AR_FORM_MODE.EDIT && (
        <Item onClick={() => navigate('/media')}>
          <StyledIcon size={21} />
          <ItemContent>
            <Title>{dataItem?.name}</Title>
            <SubTitle>{created}</SubTitle>
          </ItemContent>
        </Item>
      )}

      <StepperWrapper>
        <Stepper
          currentStep={currentStepIndex + 1}
          steps={CREATE_AR_ORDER.map((step) => ({
            label: t(`createAr.context.info.${step}.label`),
            value: t(`createAr.context.info.${step}.value`),
            ...(step === CREATE_AR_STEP.APP &&
              app?.name && {
                text: (
                  <ListSubLabel>
                    <Icons.Public size={14} />
                    {app?.name}
                  </ListSubLabel>
                ),
              }),
            ...(step === CREATE_AR_STEP.SCENARIO &&
              dataItem?.scenery && {
                text: (
                  <ListSubLabel>
                    {getIcon(dataItem?.scenery)}
                    {t(`common.scenario.${dataItem?.scenery}`)}
                  </ListSubLabel>
                ),
              }),
          }))}
        />
      </StepperWrapper>
    </Root>
  );
});
