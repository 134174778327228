import React, { FC, memo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { mutation } from '@src/adapters';
import { AuthContext } from '@src/context';
import Button from '@ui-kit/button';
import Icons from '@ui-kit/icons';
import { InputContainer, InputMessage } from '@ui-kit/input';
import Popup from '@ui-kit/popup';
import { colors } from '@ui-kit/theme';

import { DialogWindow } from '../dialog-window';
import {
  Actions,
  Disclaimer,
  Input,
  SocialIconWrapper,
  SocialWrapper,
  Text,
} from './profile-edit-dialog.styles';

interface ConfirmationDialogProps {
  isOpened: boolean;
  onClose: () => void;
}

interface ProfileForm {
  name?: string;
  email: string;
  password?: string;
  passwordRepeat?: string;
}

const dataPackageSchema = Yup.object<ProfileForm>({
  email: Yup.string(),
  name: Yup.string(),
  password: Yup.string().required(),
  passwordRepeat: Yup.string().test(
    'password-mismatch',
    'Пароли не совпадают',
    function yupTestValidateRs(
      this: { parent?: { password?: string } },
      value: string | null | undefined
    ) {
      return this?.parent?.password === value;
    }
  ),
});

export const ProfileEditDialog: FC<ConfirmationDialogProps> = memo((props) => {
  const { isOpened, onClose } = props;
  const { user, refetch } = useContext(AuthContext);
  const { t } = useTranslation();

  const [mutateUpdateUser, resultMutateUpdateUser] = useMutation(
    mutation.user.updateMe,
    {
      onCompleted: () => {
        toast.success('Данные успешно обновлены', {
          position: toast.POSITION.TOP_RIGHT,
        });
        refetch && refetch();
      },
      onError: () => {
        toast.error('Произошла ошибка. Что-то пошло не так', {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );

  const handleSubmit= (data: ProfileForm) => {
    mutateUpdateUser({
      variables: {
        firstname: data?.name?.split(' ')?.[0],
        lastname: data?.name?.split(' ')?.[1],
        email: data.email,
        password: data.password,
      },
    });
  };

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm<ProfileForm>({
    defaultValues: {
      email: user?.email,
      name:
        user?.firstname && user?.lastname
          ? `${user?.firstname} ${user?.lastname}`
          : user?.firstname || user?.lastname || '',
    },
    resolver: yupResolver(dataPackageSchema),
  });

  return (
    <Popup variant="full" isOpened={isOpened} onClose={onClose}>
      <DialogWindow size="s" title={t('me.profile.title')} onClose={onClose}>
        <InputContainer>
          <Input
            disabled={!!user?.firstname}
            {...register('name')}
            placeholder={t('sign.name')}
          />
        </InputContainer>

        <InputContainer>
          <Input
            {...register('email')}
            disabled={!!user?.email}
            placeholder={t('sign.email')}
          />
        </InputContainer>

        <InputContainer error={!!errors?.passwordRepeat || !!errors?.password}>
          <Input
            {...register('password')}
            type="password"
            placeholder={t('sign.password')}
          />
        </InputContainer>

        <InputContainer error={!!errors?.passwordRepeat || !!errors?.password}>
          <Input
            {...register('passwordRepeat')}
            type="password"
            placeholder={t('sign.passwordRepeat')}
          />
          {errors?.passwordRepeat && (
            <InputMessage>{errors?.passwordRepeat?.message}</InputMessage>
          )}
        </InputContainer>

        <Actions>
          {!resultMutateUpdateUser.loading && (
            <Button
              fullWidth
              onClick={onSubmit(handleSubmit)}
              variant="dark"
              text={t('me.profile.save')}
            />
          )}
          {resultMutateUpdateUser.loading && (
            <TailSpin
              height="32"
              width="32"
              color={colors.background.yellow}
              ariaLabel="loading"
            />
          )}
        </Actions>

        <SocialWrapper>
          <Text>{t('me.profile.linkedSocial')}</Text>
          <SocialIconWrapper isActive={!!user?.googleId}>
            <a href={`${process.env.REACT_APP_AUTH_URL}/link/google`}>
              <Icons.Google size={24} />
            </a>
          </SocialIconWrapper>
          <SocialIconWrapper isActive={!!user?.vkId}>
            <a href={`${process.env.REACT_APP_AUTH_URL}/link/vk`}>
              <Icons.Vk size={24} />
            </a>
          </SocialIconWrapper>
        </SocialWrapper>
        <Disclaimer>{t('me.profile.disclaimer')}</Disclaimer>
      </DialogWindow>
    </Popup>
  );
});
