import styled from 'styled-components';

import Icons from '../icons';
import { colors, mixins, shape } from '../theme';

export const Root = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
  })};
  background: ${colors.background.yellow};
  padding: 12px;
  box-sizing: border-box;
  border-radius: ${shape.rounding.L};
`;

export const Icon = styled(Icons.Tip)`
  margin-right: 12px;
`;

export const Text = styled.div`
  flex: 1;
  white-space: pre-line;
`;
