import React, { FC } from 'react';

const ByGeo: FC = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8947 10.6667C19.8947 11.3337 19.6615 12.3864 19.1331 13.8023C18.62 15.1771 17.89 16.7362 17.0401 18.36C15.3414 21.6054 13.2368 24.9712 11.6424 27.4044C11.2988 27.9286 10.5959 27.9286 10.2524 27.4044C8.65792 24.9712 6.55333 21.6054 4.85465 18.36C4.00475 16.7362 3.27473 15.1771 2.76167 13.8023C2.23324 12.3864 2 11.3337 2 10.6667C2 5.92885 5.95658 2 10.9474 2C15.9382 2 19.8947 5.92885 19.8947 10.6667ZM21.8947 10.6667C21.8947 14.789 16.5341 23.5884 13.3152 28.5006C12.182 30.2298 9.71274 30.2298 8.57956 28.5006C5.36068 23.5884 0 14.789 0 10.6667C0 4.77563 4.9013 0 10.9474 0C16.9934 0 21.8947 4.77563 21.8947 10.6667ZM14 10.9472C14 12.6331 12.6333 13.9998 10.9474 13.9998C9.26149 13.9998 7.89478 12.6331 7.89478 10.9472C7.89478 9.26124 9.26149 7.89453 10.9474 7.89453C12.6333 7.89453 14 9.26124 14 10.9472ZM16 10.9472C16 13.7377 13.7379 15.9998 10.9474 15.9998C8.15692 15.9998 5.89478 13.7377 5.89478 10.9472C5.89478 8.15667 8.15692 5.89453 10.9474 5.89453C13.7379 5.89453 16 8.15667 16 10.9472Z"
        fill="currentColor"
      />
    </>
  );
};

export default <ByGeo />;
