import React, { FC, ReactNode, memo } from 'react';

import { Icon, Root, Text } from './info.styles';

interface InfoProps {
  text: ReactNode;
  classname?: string;
}

export const Info: FC<InfoProps> = memo((props) => {
  const { text, classname } = props;

  return (
    <Root className={classname}>
      <Icon size={32} />
      <Text>{text}</Text>
    </Root>
  );
});
