import React, { FC, ReactNode, memo } from 'react';

import {
  Body,
  Col,
  Head,
  HeadCol,
  HoverElementWrapper,
  Root,
  Row,
  Table as StyledTable,
} from './table.styles';

interface TableColumn {
  content: ReactNode;
}

interface TableRow {
  key?: string;
  columns: TableColumn[];
}

interface TableProps {
  headers: TableRow;
  rows: TableRow[];
  hoverElement?: FC<{ onClick: (type?: string) => void }>;
  onRowClick?: (rowIndex: number) => void;
  onHoverElClick?: (rowIndex: number, type?: string) => void;
  activeIndex?: number;
}

export const Table: FC<TableProps> = memo((props) => {
  const {
    headers,
    rows,
    hoverElement: HoverElement,
    onRowClick,
    activeIndex,
    onHoverElClick,
  } = props;

  return (
    <Root>
      <StyledTable>
        <Head>
          <Row>
            {headers.columns.map(({ content }, hI) => (
              <HeadCol key={`header-col-${hI}`}>{content}</HeadCol>
            ))}
          </Row>
        </Head>
        <Body>
          {rows.map((row, rowI) => (
            <Row
              onClick={() => onRowClick && onRowClick(rowI)}
              key={row.key || `row-${rowI}`}
              index={rows.length - rowI}
              isActive={activeIndex === rowI}
            >
              {row.columns.map((col, colI) => (
                <Col key={`${row.key || `row-${rowI}`}-col-${colI}`}>
                  {col.content}
                </Col>
              ))}

              {!!HoverElement && (
                <>
                  <Col />
                  <HoverElementWrapper>
                    <HoverElement
                      onClick={(type?: string) =>
                        onHoverElClick && onHoverElClick(rowI, type)
                      }
                    />
                  </HoverElementWrapper>
                </>
              )}
            </Row>
          ))}
        </Body>
      </StyledTable>
    </Root>
  );
});
