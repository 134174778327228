import React, { FC, memo } from 'react';

import { StyledSlider } from './range-slider.styles';

interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

export const RangeSlider: FC<RangeSliderProps> = memo((props) => {
  return <StyledSlider {...props} />;
});
