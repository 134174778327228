import React, { FC, memo, useEffect, useState } from 'react';

import { Label, Root, StyledIcon } from './chip.styles';

interface ChipProps {
  name?: string;
  label: string;
  isActive?: boolean;
  onClick?: (name: string, value?: boolean) => void;
}

export const Chip: FC<ChipProps> = memo((props) => {
  const { name, label, isActive, onClick } = props;

  const handleChange = () => {
    if (onClick && name) {
      onClick(name, !isActive);
    }
  };

  return (
    <Root isActive={isActive} onClick={handleChange}>
      <Label>{label}</Label>
      <StyledIcon size={20} />
    </Root>
  );
});
