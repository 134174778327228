import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  memo,
  useContext,
  useEffect,
} from 'react';

import { CreateArContext } from '@src/context';
import {
  Format,
  FormatByType,
  findTypeByFormat,
} from '@src/pages/create-ar/utils';

import {
  Input,
  InputWrapper,
  Label,
  Root,
  StyledFileUploader,
  Text,
} from './form-base-info.styles';

interface FormBaseInfoProps {}

export const FormBaseInfo: FC<FormBaseInfoProps> = memo(() => {
  const { onDataItemChange, setDisabledNext, dataItem } =
    useContext(CreateArContext);

  useEffect(() => {
    setDisabledNext(
      !dataItem?.name || !dataItem.description || !dataItem.thumbnailFile
    );
  }, [dataItem?.name, dataItem?.description, dataItem?.thumbnailFile]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDataItemChange((dataItem) => {
      return { ...dataItem, [e.target.name]: e.target.value.slice(0, 101) };
    });
  };
  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onDataItemChange((dataItem) => {
      return { ...dataItem, [e.target.name]: e.target.value.slice(0, 101) };
    });
  };
  const handleFileChange = (ref: MutableRefObject<HTMLInputElement | null>) => {
    const file = ref.current?.files && ref.current?.files[0];

    if (file) {
      let format = file.type.split('/')?.[1];
      if (!format) {
        const splitName = file.name.split('.');
        format = splitName[splitName.length - 1];
      }
      const itemType = findTypeByFormat(format);
      if (itemType) {
        onDataItemChange((item) => {
          return {
            ...item,
            thumbnailFile: file,
          };
        });
        setDisabledNext(!file);
      }
      if (itemType !== 'image') {
        setDisabledNext(true);
      }
    }
  };

  return (
    <Root>
      <InputWrapper>
        <Label>Название</Label>
        <Input
          value={dataItem?.name || ''}
          name="name"
          onChange={handleChange}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>Описание</Label>
        <Text
          value={dataItem?.description || ''}
          name="description"
          onChange={handleTextChange}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>Логотип</Label>
        <StyledFileUploader
          imageUrl={(dataItem as any)?.thumbnailFile}
          selectedFileName={dataItem?.thumbnailFile?.name}
          onChange={handleFileChange}
        />
      </InputWrapper>
    </Root>
  );
});
