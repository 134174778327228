import styled, { css } from 'styled-components';

import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  width: 100%;
  ${mixins.flex({
    horizontalAlignment: 'center',
    verticalAlignment: 'flex-start',
  })};
  margin-top: 80px;
`;

export const Container = styled.div`
  width: 420px;
  margin-right: 120px;
`;

export const Title = styled.h1`
  ${typography.bold40};
  color: ${colors.white};
  margin-bottom: 102px;
  margin-top: 0;
`;

export const Text = styled.div`
  ${typography.regular18};
  color: ${colors.white};
  margin-bottom: 20px;
`;

export const AuthFormWrapper = styled.div`
  background: ${colors.background.white};
  border-radius: ${shape.rounding.L};
  padding: 52px 40px;
  box-sizing: border-box;
  width: 400px;
  min-height: 432px;

  ${mixins.flex({ horizontal: false, verticalAlignment: 'space-between' })}
`;

export const AuthFormTitle = styled.div`
  ${typography.bold24};
  margin-bottom: 32px;
  text-align: center;
`;

export const InputWrapper = styled.div<{ error?: boolean }>`
  margin-bottom: 12px;
  width: 100%;

  ${({ error }) =>
    error &&
    css`
      color: ${colors.text.red};
      ${AuthInput} {
        border-color: ${colors.text.red};
      }
    `}
`;

export const InputMessage = styled.div`
  ${typography.regular12};
  margin-top: 4px;
  margin-left: 12px;
`;

export const AuthInput = styled.input`
  ${typography.regular14};
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;

  border-radius: ${shape.rounding.M};
  border: 1px solid ${colors.background.grayLight};

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;
// /api/v1/auth/signup - принимает login, password, email
//
// /api/v1/auth/signup
// /api/v1/auth/signin - принимает email, password

export const Wrapper = styled.div`
  ${mixins.flex({ horizontal: false })};
`;
export const SocialWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  })};

  a {
    text-decoration: none;
    color: ${colors.text.gray};
    transition: all ease ${animations.duration};
    margin: 0 4px;

    &:hover {
      color: ${colors.text.orange};
    }
  }
`;

export const Label = styled.div`
  ${typography.regular12};
  text-align: center;
  margin-bottom: 12px;
`;
