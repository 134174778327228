import styled, { css } from 'styled-components';

import { animations, colors, mixins } from '@ui-kit/theme';

export const Root = styled.div``;

export const Dot = styled.div<{
  active?: boolean;
  variant?: 'empty';
  thumbnail?: string;
}>`
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all ease ${animations.duration};

  ${({ active }) =>
    active &&
    css`
      border-color: ${colors.dividers.darkStandard};
    `}

  &:after {
    content: '';
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: ${colors.white};
    background-image: url(${({ thumbnail }) => thumbnail});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    border-radius: 50%;
    transition: all ease ${animations.duration};

    ${({ variant }) =>
      variant === 'empty' &&
      css`
        opacity: 0;
      `}
  }

  ${({ variant }) =>
    variant === 'empty' &&
    css`
      border-color: ${colors.dividers.darkStandard};
      border-style: dashed;
    `}

  ${({ active, variant }) =>
    variant === 'empty' &&
    active &&
    css`
      &:after {
        opacity: 1;
        background: ${colors.background.yellow};
      }
    `}
`;

export const Item = styled.div`
  ${mixins.flex({ verticalAlignment: 'center' })};
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    ${Dot} {
      &:after {
        // background: ${colors.background.yellow};
        opacity: 1;
      }
    }
  }
`;

export const ItemContent = styled.div`
  margin-left: 12px;
  flex: 1;
`;
