import React, {
  FC,
  ReactNode,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Render3D from '@src/components/render-3d';
import { CreateArContext } from '@src/context';
import {
  CREATE_AR_FORM_MODE,
  ITEM_TAB,
  ITEM_TYPE,
  SCENARIO,
} from '@src/core/types';
import { useNav } from '@src/hooks/use-nav';
import { useVideoMeta } from '@src/hooks/use-video-meta';
import Button from '@ui-kit/button';
import Icons from '@ui-kit/icons';
import Info from '@ui-kit/info';

import FormBaseInfo from './components/form-base-info';
import FormContent from './components/form-content';
import FormDataPackage from './components/form-data-package';
import FormSettings from './components/form-settings';
import FormTrigger from './components/form-trigger';
import {
  ActionsRow,
  InfoWrapper,
  NavWrapper,
  Root,
  Switcher,
  Title,
} from './screen-add-item.styles';

interface SelectScenarioProps {
  onNextClick: () => void;
  onPrevClick: () => void;
}

const InfoBlock: FC<{ currentTab: ITEM_TAB }> = ({ currentTab }) => {
  switch (currentTab) {
    case ITEM_TAB.TRIGGER:
      return <FormTrigger />;
    case ITEM_TAB.UPLOAD:
      return <FormContent />;
    case ITEM_TAB.SETTINGS:
      return <FormSettings />;
    case ITEM_TAB.NAME:
      return <FormBaseInfo />;
    case ITEM_TAB.DATA_PACKAGE:
      return <FormDataPackage />;
    default:
      return null;
  }
};

const ORDER = [
  ITEM_TAB.TRIGGER,
  ITEM_TAB.UPLOAD,
  ITEM_TAB.SETTINGS,
  ITEM_TAB.NAME,
  ITEM_TAB.DATA_PACKAGE,
];

const ORDER_EDIT = [ITEM_TAB.SETTINGS, ITEM_TAB.NAME, ITEM_TAB.DATA_PACKAGE];

const TABS: { value: string; content: ReactNode; disabled?: boolean }[] = [
  {
    value: ITEM_TAB.TRIGGER,
    content: <Icons.Cube size={32} />,
  },
  {
    value: ITEM_TAB.UPLOAD,
    content: <Icons.Play size={32} />,
  },
  {
    value: ITEM_TAB.SETTINGS,
    content: <Icons.Adjustments size={32} />,
  },
  {
    value: ITEM_TAB.NAME,
    content: <Icons.Information size={32} />,
  },
  {
    value: ITEM_TAB.DATA_PACKAGE,
    content: <Icons.Database size={32} />,
  },
];
const createImageUrl = (buffer: any, type: any) => {
  const blob = new Blob([buffer], { type });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
};

export const ScreenAddItem: FC<SelectScenarioProps> = memo((props) => {
  const { onPrevClick, onNextClick } = props;
  const [currentTab, setCurrentTab] = useState<ITEM_TAB>(ITEM_TAB.TRIGGER);
  const { dataItem, disabledNext, mode } = useContext(CreateArContext);
  const [triggerUrl, setTriggerUrl] = useState<string | undefined>(
    dataItem?.triggerFilePath
  );

  const [modelUrl, setModelUrl] = useState<string | undefined>();
  const [order, setOrder] = useState(
    mode === CREATE_AR_FORM_MODE.EDIT ? ORDER : ORDER
  );
  const videoMeta = useVideoMeta(
    dataItem?.type === ITEM_TYPE.VIDEO ? dataItem?.itemFile : undefined
  );

  const { t } = useTranslation();

  const handlePrevClick = () => {
    if (currentIndex === 0) {
      onPrevClick();
    } else {
      handlePrev();
    }
  };
  const handleNextClick = () => {
    if (currentIndex === order.length - 1) {
      onNextClick();
    } else {
      handleNext();
    }
  };

  const tabs = useMemo(() => {
    if (mode === CREATE_AR_FORM_MODE.EDIT) {
      const filter = TABS.map((tab) =>
        tab.value === ITEM_TAB.TRIGGER || tab.value === ITEM_TAB.UPLOAD
          ? { ...tab, disabled: true }
          : tab
      );
      const activeItem = filter.find((tab) => !tab.disabled)?.value;
      activeItem && setCurrentTab(activeItem as ITEM_TAB);

      return filter;
    }

    switch (dataItem?.scenery) {
      case SCENARIO.IN_SPACE:
      case SCENARIO.BY_BODYPART: {
        const filter = TABS.filter(({ value }) => value !== ITEM_TAB.TRIGGER);
        if (currentTab === ITEM_TAB.TRIGGER) {
          setCurrentTab(filter[0].value as ITEM_TAB);
          setOrder((prev) => prev.filter((item) => item !== ITEM_TAB.TRIGGER));
        }
        return filter;
      }
      default:
        return TABS;
    }
  }, [dataItem?.scenery, mode]);
  const { currentIndex, handlePrev, handleNext } = useNav<ITEM_TAB>(
    tabs.map((tab) => tab.value) as ITEM_TAB[],
    currentTab,
    setCurrentTab
  );

  useEffect(() => {
    /*if (!dataItem?.triggerFile && !!triggerUrl) {
      setTriggerUrl(undefined);
    }*/
    if (!dataItem?.triggerFile || dataItem?.scenery === SCENARIO.BY_OBJECT) {
      return;
    }

    const file = dataItem?.triggerFile;
    const { type } = file;
    file.arrayBuffer().then((buffer: any) => {
      setTriggerUrl(createImageUrl(buffer, type));
    });
  }, [dataItem?.triggerFile]);
  useEffect(() => {
    if (!dataItem?.itemFile) {
      return;
    }

    const file = dataItem?.itemFile;
    const { type } = file;

    if (type.indexOf('video') > -1) {
      return;
    }

    file.arrayBuffer().then((buffer: any) => {
      setModelUrl(createImageUrl(buffer, type));
    });
  }, [dataItem?.itemFile]);

  const show3dScene = true; // dataItem?.scenery === SCENARIO.BY_IMAGE;
  const backBtnDisabled =
    mode === CREATE_AR_FORM_MODE.EDIT && tabs[currentIndex - 1]?.disabled;

  return (
    <Root>
      <Title>{t(`createAr.addItem.title.${currentTab}`)}</Title>

      <Switcher
        tabs={tabs}
        info={<InfoBlock currentTab={currentTab} />}
        content={
          <>
            {show3dScene && (
              <Render3D
                modelPath={modelUrl || dataItem?.filePath}
                texturePath={triggerUrl}
                scale={dataItem?.scale || 1}
                modelType={dataItem?.type}
                position={[
                  dataItem?.offsetX || 0,
                  dataItem?.offsetY || 0,
                  dataItem?.offsetZ || 0,
                ]}
                width={videoMeta?.width}
                height={videoMeta?.height}
              />
            )}
            {!show3dScene && (
              <Title>{t('createAr.addItem.content.empty')}</Title>
            )}
          </>
        }
        currentTab={currentTab}
      />

      <ActionsRow>
        <InfoWrapper>
          <Info text={t(`createAr.addItem.tip.${ITEM_TAB.TRIGGER}`)} />
        </InfoWrapper>

        <NavWrapper>
          <Button
            disabled={backBtnDisabled}
            text="Назад"
            variant="outline"
            onClick={handlePrevClick}
          />
          <Button
            disabled={disabledNext}
            text="Далее"
            variant="dark"
            onClick={handleNextClick}
          />
        </NavWrapper>
      </ActionsRow>
    </Root>
  );
});
