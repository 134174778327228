import React, { FC } from 'react';

const Google: FC = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8036 13.8043H16.2324C16.2324 14.9037 16.2324 17.1022 16.2257 18.2016H22.3514C22.1167 19.301 21.2844 20.8404 20.1084 21.6154C20.1073 21.6144 20.1062 21.6219 20.1039 21.6208C18.5405 22.6531 16.4773 22.8874 14.9453 22.5796C12.5439 22.1024 10.6435 20.3609 9.8719 18.149C9.87639 18.1457 9.87978 18.1152 9.88315 18.113C9.40018 16.7409 9.40018 14.9037 9.88315 13.8043H9.88202C10.5043 11.7836 12.4619 9.94006 14.8666 9.43545C16.8007 9.02538 18.983 9.46929 20.588 10.971C20.8014 10.7622 23.5419 8.08638 23.7475 7.8687C18.2642 2.90281 9.48441 4.64967 6.19915 11.0623H6.19803C6.19803 11.0623 6.19916 11.0626 6.19242 11.0747C4.5672 14.2244 4.63459 17.9358 6.20365 20.9349C6.19916 20.9382 6.19579 20.9402 6.19242 20.9435C7.61435 23.703 10.2021 25.8192 13.32 26.6251C16.6322 27.4936 20.8475 26.9 23.6711 24.3461C23.6722 24.3472 23.6734 24.3482 23.6745 24.3493C26.0669 22.1945 27.5562 18.5239 26.8036 13.8043Z"
        fill="currentColor"
      />
    </>
  );
};

export default <Google />;
