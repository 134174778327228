import styled, { css } from 'styled-components';

import Icons from '@ui-kit/icons';
import { colors, shadow } from '@ui-kit/theme';

export interface PopupProps {
  variant?: 'full' | 'full-screen-window' | 'window' | 'center';
  transparentBackground?: boolean;
}

export const Popup = styled.div<PopupProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'center':
      case 'window':
        return css`
          display: inline-block;
          border-radius: 15px;
          box-shadow: ${shadow.M};
          padding: 20px;
        `;
      default:
        return css`
          min-width: 100%;
          min-height: 100%;
          height: 100%;
        `;
    }
  }}
`;

export const StyledIconClose = styled(Icons.Delete)`
  //TODO icon close
  color: ${colors.white};
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: -24px;
  top: 2px;
`;
