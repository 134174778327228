import styled, { css } from 'styled-components';

import { animations, colors, mixins, typography } from '../theme';

export const Root = styled.div``;

export const StepItem = styled.div<{ hasAction?: boolean }>`
  ${mixins.flex({})}
  color: ${colors.white};
  margin-bottom: 40px;
  transition: color ease ${animations.duration};

  ${({ hasAction }) =>
    hasAction &&
    css`
      cursor: pointer;
      &:hover {
        color: ${colors.accent.yellow};
        ${StepIcon} {
          color: ${colors.accent.yellow};
        }
      }
    `}
`;

export const StepIcon = styled.div`
  flex: 0;
  margin-right: 8px;
`;

export const StepContent = styled.div`
  flex: 1;
`;

export const StepLabel = styled.div`
  ${typography.bold18};
`;

export const StepValue = styled.div`
  ${typography.regular16};
  margin-top: 32px;
`;

export const StepText = styled.div`
  ${typography.regular12};
  color: ${colors.text.grayExtraLight};
  margin-top: 16px;
`;
