import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

export interface RootProps {
  ml?: number;
  mr?: number;
  mt?: number;
  mb?: number;
  br?: number;
}

export const Root = styled(Skeleton).attrs({
  duration: 2,
  'data-component': 'skeleton',
})<RootProps>`
  ${({ br }) =>
    br &&
    br > 0 &&
    css`
      border-radius: ${br}px !important;
    `}

  ${({ ml }) =>
    ml &&
    ml > 0 &&
    css`
      margin-left: ${ml}px;
    `}

  ${({ mr }) =>
    mr &&
    mr > 0 &&
    css`
      margin-right: ${mr}px;
    `}

  ${({ mt }) =>
    mt &&
    mt > 0 &&
    css`
      margin-top: ${mt}px;
    `}

  ${({ mb }) =>
    mb &&
    mb > 0 &&
    css`
      margin-bottom: ${mb}px;
    `}
`;
