import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import Icons from '@ui-kit/icons';
import Select from '@ui-kit/select';

import eyePath from '../../assets/images/eye.svg';
import logoPath from '../../assets/images/logo.svg';
import {
  Avatar,
  Eye,
  Logo,
  LogoWrapper,
  Menu,
  MenuItem,
  Root,
  ViewsContainer,
  ViewsText,
} from './header.styles';

interface HeaderProps {
  className?: string;
  onDrawerOpen?: () => void;
}

export const Header: FC<HeaderProps> = memo((props) => {
  const { className } = props;
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const { data } = useQuery<{
    me: {
      avararUrl: string | null;
      createdAt: string | null;
      email: string | null;
      firstname: string | null;
      id: 1;
      lastname: string | null;
      nickname: string | null;
      balance: {
        current_balance: number;
      };
    };
  }>(queries.user.me);

  const handleLangChange = useCallback(
    (lang: string) => {
      localStorage.setItem('lng', lang);
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  const MENU_ITEMS = useMemo(
    () => [
      {
        label: t('common.menu.createAr'),
        path: '/create-ar',
      },
      {
        label: t('common.menu.media'),
        path: '/media',
      },
      {
        label: t('common.menu.library'),
        path: '/library',
      },
      {
        label: t('common.menu.faq'),
        path: '/faq/1',
      },
    ],
    [t]
  );

  return (
    <Root className={className}>
      <LogoWrapper>
        <Link to="/">
          <Logo src={logoPath} />
        </Link>
      </LogoWrapper>

      <Menu>
        {MENU_ITEMS.map(({ label, path }) => (
          <MenuItem
            isActive={pathname.indexOf(path) !== -1}
            key={`header-menu-item~${path}`}
          >
            <Link to={path}>{label}</Link>
          </MenuItem>
        ))}
      </Menu>

      <Menu>
        <MenuItem margin={12}>
          {data && (
            <Link to={'/payment'}>
              <ViewsContainer>
                <ViewsText>
                  {data?.me?.balance?.current_balance.toLocaleString('ru')}
                </ViewsText>
                <Eye src={eyePath} />
              </ViewsContainer>
            </Link>
          )}
        </MenuItem>
        <MenuItem margin={12}>
          {data && (
            <Link to={'/me'}>
              <Avatar name={data.me.firstname ?? ''} />
            </Link>
          )}
          {!data && (
            <a href={`${process.env.REACT_APP_AUTH_URL}/google`}>Войти</a>
          )}
        </MenuItem>
        <MenuItem margin={12}>
          <Select
            value={i18n.language}
            headerItem={<Icons.Locale size={20} />}
            options={[
              { value: 'ru', label: 'RU' },
              { value: 'en', label: 'EN' },
            ]}
            onChange={handleLangChange}
          />
        </MenuItem>
      </Menu>
    </Root>
  );
});
