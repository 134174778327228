import React from 'react';
import { createRoot } from 'react-dom/client';

import Root from './core/root';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);

  root.render(<Root />);
}
