import styled, { css } from 'styled-components';

import SwitcherUi, { styles } from '@ui-kit/switcher';
import { colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  max-width: 1200px;
  margin: auto;
  ${mixins.flex({ horizontal: false, wrap: false })};
  flex: 1;
  height: 100%;
`;

export const Title = styled.h1`
  ${typography.medium24};
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const Row = styled.div`
  ${mixins.flex({})};
  margin-bottom: 20px;
`;

export const Value = styled.div`
  ${typography.bold16};
`;

export const Dot = styled.div<{ active?: boolean; variant?: 'empty' }>`
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 50%;
  border: 2px solid transparent;

  ${({ active }) =>
    active &&
    css`
      border-color: ${colors.dividers.darkStandard};
    `}

  &:after {
    content: '';
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: ${colors.white};
    position: absolute;
    border-radius: 50%;

    ${({ variant }) =>
      variant === 'empty' &&
      css`
        content: none;
      `}
  }

  ${({ variant }) =>
    variant === 'empty' &&
    css`
      border-color: ${colors.dividers.darkStandard};
      border-style: dashed;
    `}
`;

export const Item = styled.div`
  ${mixins.flex({ verticalAlignment: 'center' })};
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ItemContent = styled.div`
  margin-left: 12px;
  flex: 1;
`;

export const ActionsWrapper = styled.div`
  ${mixins.flex({ horizontalAlignment: 'flex-end' })}
`;

export const Switcher = styled(SwitcherUi)`
  min-height: 650px;
  ${styles.ColumnContent} {
    padding: 0;
    overflow: auto;
    ${mixins.scrolls()};
  }
  max-height: calc (100vh - 20px);
`;

export const Profile = styled.div`
  margin-bottom: 40px;
  ${mixins.flex({})}
`;
export const ProfileName = styled.div`
  margin-left: 12px;
  ${typography.bold24};
`;
