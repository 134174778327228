import styled, { css } from 'styled-components';

import {
  Listbox,
  ListboxButton,
  ListboxInput,
  ListboxInputProps,
  ListboxList,
  ListboxOption,
  ListboxPopover,
  ListboxPopoverProps,
} from '@reach/listbox';
import '@reach/listbox/styles.css';
import Icons from '@ui-kit/icons';
import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

import { Variant } from './select';

export const Root = styled.div``;

export const List = styled(ListboxList)`
  color: ${colors.text.white};
  padding: 8px;
  box-sizing: border-box;

  ${typography.regular14};
`;

export const Option = styled(ListboxOption)`
  border-radius: ${shape.rounding.S};
  cursor: pointer;
  ${mixins.flex({
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
  })};

  &[data-reach-listbox-option][data-current-nav] {
    background: ${colors.background.grayStandard};
  }

  &:hover {
    background: ${colors.background.grayStandard};
  }
`;

interface PopoverProps extends ListboxPopoverProps {
  variant?: Variant;
}

export const Popover = styled(ListboxPopover)<PopoverProps>`
  animation: slide-in-blurred-top 0.2s ease both;
  background: ${colors.background.grayDark} !important;
  border-radius: ${shape.rounding.M};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 100;

  border: none !important;

  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }

  ${({ variant }) =>
    variant === 'round' &&
    css`
      margin-top: 14px;
      ${List} {
        padding: 4px;
      }
    `};
`;

export const Button = styled(ListboxButton)`
  cursor: pointer;
  padding-right: 24px;
`;

export const ArrowIcon = styled(Icons.Arrow)`
  transition: transform ease ${animations.duration};
  margin-left: 8px;
`;

interface InputProps extends ListboxInputProps {
  variant?: Variant;
  colorVariant?: 'dark' | 'default';
}

export const Input = styled(ListboxInput)<InputProps>`
  border-radius: ${shape.rounding.M};
  background: ${colors.background.grayDark};
  color: ${colors.text.white};
  ${typography.bold14};
  transition: border-radius ease-out ${animations.duration};
  ${mixins.flex({
    verticalAlignment: 'center',
  })};

  ${({ colorVariant }) =>
    colorVariant === 'dark' &&
    css`
      background: ${colors.background.grayDark};
    `}

  & > span {
    padding: 12px 16px;
    border: none;
    width: 100%;
    box-sizing: border-box;

    &:focus-visible {
      outline: none;
    }
  }

  ${({ variant }) =>
    variant === 'round' &&
    css`
      border-radius: 50%;
      width: 42px;
      height: 42px;
      ${mixins.flex({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
      })};

      & > span {
        width: 100%;
        padding: 0;
        flex: 1;
        ${mixins.flex({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
        })};
        svg {
          margin: 0 !important;
        }
      }
    `};

  &[data-state='expanded'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    ${Button} {
      ${ArrowIcon} {
        transform: rotate(180deg);
      }
    }
  }
`;

export const ListRoot = styled(Listbox)``;
