import React, { FC, ReactNode, memo } from 'react';

import { Icon, Label, Root } from './checkbox.styles';

export type Variant = 'default' | 'accent' | 'light';

export const styles = {
  Icon,
  Label,
  Root,
};

interface CheckboxProps {
  checked?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (value: boolean, name?: string) => void;
  variant?: Variant;
  className?: string;
}

export const Checkbox: FC<CheckboxProps> = memo((props) => {
  const {
    checked,
    label,
    onChange,
    name,
    className,
    variant = 'default',
  } = props;

  const handleChange = () => onChange && onChange(!checked, name);

  return (
    <Root onClick={handleChange} className={className}>
      <Icon checked={checked} variant={variant} />

      {label && <Label>{label}</Label>}
    </Root>
  );
});
