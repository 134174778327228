import React, { FC, memo, useEffect, useState } from 'react';

import Chip from '@ui-kit/chip';

import { Label, Root, StyledIcon } from './chips-group.styles';

interface ChipProps {
  items: { label: string; value: string }[];
  onChange?: (values: string[], name: string) => void;
  name: string;
  value?: string[];
}

export const ChipsGroup: FC<ChipProps> = memo((props) => {
  const { items, onChange, name: groupName, value } = props;
  const [selectedItems, setSelectedItems] = useState<string[]>(value || []);

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  // const handleChange = () => setActive(!active);
  const handleChange = (name: string, value?: boolean) => {
    setSelectedItems((prev) => {
      let nextValues;
      if (value) {
        nextValues = [...prev, name];
      } else {
        nextValues = prev.filter((val) => val !== name);
      }
      if (onChange) {
        onChange(nextValues, groupName);
      }
      return nextValues;
    });
  };

  return (
    <Root>
      {items.map(({ label, value }) => (
        <Chip
          onClick={handleChange}
          key={`chip~${value}`}
          label={label}
          name={value}
          isActive={selectedItems.indexOf(value) > -1}
        />
      ))}
    </Root>
  );
});
