import styled from 'styled-components';

import { colors } from '@src/ui-kit/theme';

export const Root = styled.div`
  margin-top: 10px;
`;
export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
`;

export const Eye = styled.img`
  width: 30px;
  height: 30px;
`;

export const TextBalance = styled.p`
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.text.white};
`;

export const Text = styled.p`
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.text.white};
`;

export const TextSider = styled.p`
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.text.white};
`;

export const TextBold = styled.b`
  font-weight: 600;
`;
