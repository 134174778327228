import React, { FC, ReactNode, memo } from 'react';

import {
  ArrowIcon,
  Button,
  Input,
  List,
  Option,
  Popover,
} from './select.style';

interface SelectProps {
  value?: string;
  onChange?: (newValue: string) => void;
  options: {
    label: ReactNode;
    value: string;
  }[];
  placeholder?: ReactNode;
  className?: string;
  variant?: 'round' | 'default';
  colorVariant?: 'dark' | 'default';
  headerItem?: ReactNode;
}
export type Variant = 'round' | 'default';

export const Select: FC<SelectProps> = memo((props) => {
  const {
    className,
    value,
    options,
    onChange,
    variant = 'default',
    colorVariant = 'default',
    placeholder,
    headerItem,
  } = props;

  const currentLabel = options.find((option) => option.value === value);

  return (
    <Input
      variant={variant}
      colorVariant={colorVariant}
      className={className}
      value={value}
      onChange={onChange}
    >
      {!headerItem && (
        <Button>
          {(currentLabel?.value && currentLabel?.label) || placeholder}
          {variant !== 'round' && <ArrowIcon size={12} />}
        </Button>
      )}
      {!!headerItem && <Button>{headerItem}</Button>}
      <Popover variant={variant}>
        <List>
          {options.map(({ label, value }, i) => (
            <Option key={`option~${value}~${i}`} value={value}>
              {label}
            </Option>
          ))}
        </List>
      </Popover>
    </Input>
  );
});
