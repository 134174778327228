import React, { FC, memo } from 'react';

import { Root } from './button.styles';

export type Variant = 'dark' | 'outline';

interface ButtonProps {
  text: string;
  variant?: Variant;
  disabled?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  style?: any;
  disabledWhite?: boolean;
}

export const Button: FC<ButtonProps> = memo((props) => {
  const { text, variant, disabled, disabledWhite, style, ...other } = props;

  return (
    <Root
      variant={variant}
      disabled={disabled}
      style={style}
      {...other}
      disabledWhite={disabledWhite}
    >
      {text}
    </Root>
  );
});
