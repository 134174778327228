import React, { Suspense, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import { OrbitControls } from '@react-three/drei';
import { useFrame, useLoader } from '@react-three/fiber';
import { Canvas } from '@react-three/fiber';
import PlayBtnPth from '@src/assets/images/play-button.png';
import { ITEM_TYPE } from '@src/core';

const GltfModel = ({ modelPath, scale = 40, position = [0, 0, 0] }: any) => {
  const ref = useRef();
  const gltf = useLoader(GLTFLoader, modelPath as string);
  const [hovered, hover] = useState(false);

  let yPos: number = (ref?.current as any)?.rotation?.y || 0;
  useFrame((state, delta) => (yPos += 0.003));

  return (
    <primitive
      ref={ref}
      object={gltf.scene}
      position={position}
      scale={hovered ? scale : scale}
      onPointerOver={(event: any) => hover(true)}
      onPointerOut={(event: any) => hover(false)}
    />
  );
};

// https://codesandbox.io/s/elated-fire-198px?file=/src/App.js
const LoadMesh = ({
  texturePath,
  scale = 1,
  position,
  width,
  height,
  transparent = true,
}: any) => {
  const colorMap = useLoader(TextureLoader, (texturePath as string) || '');
  const triggerSizeOptions = useMemo(
    () => [
      1,
      (height || colorMap?.source?.data?.height) /
        (width || colorMap?.source?.data?.width || 1),
      0,
    ],
    [colorMap]
  );

  return (
    <mesh scale={scale} position={position}>
      <boxGeometry args={triggerSizeOptions as any} scale={scale} />
      <meshStandardMaterial transparent={transparent} map={colorMap} />
    </mesh>
  );
};

const Scene = ({
  texturePath, //imageUrl,
  modelPath, // URL,
  modelType,
  scale = 1,
  position = [0, 0, 0],
  width,
  height,
}: any) => {
  return (
    <>
      <ambientLight intensity={0.3} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <gridHelper scale={1} />

      {texturePath && (
        <LoadMesh texturePath={texturePath} position={[0, 0, 0 - 0.01]} />
      )}

      {modelType === ITEM_TYPE.OBJECT && modelPath && (
        <>
          <GltfModel modelPath={modelPath} scale={scale} position={position} />
        </>
      )}

      {(modelType === ITEM_TYPE.IMAGE || modelType === ITEM_TYPE.WIDGET) &&
        modelPath && (
          <LoadMesh texturePath={modelPath} scale={scale} position={position} />
        )}

      {modelType === ITEM_TYPE.VIDEO && (
        <LoadMesh
          key={width?.toString() + height?.toString()}
          width={width}
          height={height}
          texturePath={PlayBtnPth}
          scale={scale}
          position={position}
          transparent={false}
        />
      )}
      <OrbitControls />

      {/*{modelType === ITEM_TYPE.VIDEO && modelPath && (
        <LoadMesh texturePath={modelPath} />
      )}*/}
    </>
  );
};

export const Render3D = (props: any) => {
  return (
    <ErrorBoundary
      onError={(e: any) => {
        console.log(' e ', e);
      }}
      fallback={<div>Не удалось отобразить сцену</div>}
    >
      <Canvas camera={{ fov: 10, position: [0, 1, 10] }}>
        <Suspense fallback={null}>
          <Scene {...props} />
        </Suspense>
      </Canvas>
    </ErrorBoundary>
  );
};
