import React, { FC, memo, useCallback } from 'react';

import { dateFormatter } from '@src/core/utils';

import {
  StatsItem,
  ViewsByDateItem,
} from '../modal-statistics/modal-statistics';
import './chart.styles';
import { Area, Column, Root, TextDate } from './chart.styles';

interface SidebarCreateArProps {
  info: StatsItem | null;
  selectedDate: ViewsByDateItem | null;
  setSelectedDate: (value: ViewsByDateItem | null) => void;
  hoverdDate: ViewsByDateItem | null;
  setHoverdDate: (value: ViewsByDateItem | null) => void;
}

export const Chart: FC<SidebarCreateArProps> = memo(
  ({ selectedDate, setSelectedDate, info, hoverdDate, setHoverdDate }) => {
    const views_arr = info?.viewsByDate.map((it) => it.totalViews);

    const max = views_arr ? Math.max(...views_arr) : 0;
    const len = info?.viewsByDate?.length || 0;
    return (
      <Root
        style={{
          justifyContent: len < 28 ? 'center' : 'space-between',
        }}
      >
        <Area
          style={{
            justifyContent: len < 28 ? 'center' : 'space-between',
          }}
        >
          {info?.viewsByDate.map((it, index) => (
            <Column
              key={index}
              style={{
                height: `${(it.totalViews * 100) / max}%`,
                minHeight: 10,
              }}
              selectedDate={
                hoverdDate === null
                  ? selectedDate?.date === it.date
                  : hoverdDate?.date === it.date
              }
              onClick={(event) => {
                event.stopPropagation();
                setSelectedDate(it);
              }}
              onMouseOver={(event) => {
                event.stopPropagation();
                setHoverdDate(it);
              }}
              onMouseOut={(event) => {
                event.stopPropagation();
                setHoverdDate(null);
              }}
            />
          ))}
        </Area>

        <TextDate
          style={{
            textAlign: len < 28 ? 'center' : 'start',
          }}
        >
          {hoverdDate
            ? dateFormatter(hoverdDate.date) + ' - ' + hoverdDate.totalViews
            : selectedDate
            ? dateFormatter(selectedDate.date) + ' - ' + selectedDate.totalViews
            : ''}
        </TextDate>
      </Root>
    );
  }
);
