import React, { FC } from 'react';

const Close: FC = () => {
  return (
    <>
      <rect width="30" height="30" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2136 8.07107C21.8231 7.68054 21.19 7.68054 20.7994 8.07107L15.1426 13.7279L9.48572 8.07107C9.0952 7.68054 8.46204 7.68054 8.07151 8.07107C7.68099 8.46159 7.68099 9.09476 8.07151 9.48528L13.7284 15.1421L8.07151 20.799C7.68099 21.1895 7.68099 21.8227 8.07151 22.2132C8.46204 22.6037 9.0952 22.6037 9.48572 22.2132L15.1426 16.5563L20.7994 22.2132C21.19 22.6037 21.8231 22.6037 22.2136 22.2132C22.6042 21.8227 22.6042 21.1895 22.2136 20.799L16.5568 15.1421L22.2136 9.48528C22.6042 9.09476 22.6042 8.46159 22.2136 8.07107Z"
        fill="currentColor"
      />
    </>
  );
};

export default <Close />;
