import styled, { css } from 'styled-components';

import Icons from '@ui-kit/icons';
import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div<{ isActive?: boolean }>`
  padding: 5px 14px;
  border-radius: 6px;
  color: ${colors.background.grayDark};
  background: ${colors.background.white};
  ${typography.regular14};
  ${mixins.flex({ verticalAlignment: 'center' })};
  cursor: pointer;
  margin: 0 8px;
  transition: background ease ${animations.duration};

  &:hover {
    background: ${colors.background.grayExtraLight};

    ${({ isActive }) =>
      isActive &&
      css`
        background: ${colors.background.grayExtraDark};
      `}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.background.grayDark};
      color: ${colors.text.white};

      ${StyledIcon} {
        transform: rotate(0deg);
      }
    `};
`;

export const Label = styled.div``;

export const StyledIcon = styled(Icons.Close)`
  margin-left: 4px;
  transform: rotate(45deg);
  transition: transform ease ${animations.duration};
`;
