import styled, { css } from 'styled-components';

import { colors, shape } from '@ui-kit/theme';

export const Root = styled.div<{ src?: string }>`
  width: 32px;
  height: 32px;
  border-radius: ${shape.rounding.S};
  background: ${colors.background.grayExtraLight};

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-size: cover;
      background-position: center;
      cursor: pointer;
    `}
`;
