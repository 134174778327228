import { colors } from './colors';
import { flex } from './flex';
import { shadow } from './shadow';

export const mixins = {
  flex,
  block: () => `
    border-radius: 15px;
    background-color: ${colors.white};
    box-shadow: ${shadow.S};
  `,
  textOverflow: () => `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  scrolls: () => `
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(196, 196, 196);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.25);
  }
`,
};
