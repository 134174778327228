import React, { FC } from 'react';

const Delete: FC = () => {
  return (
    <g opacity="0.5">
      <path
        d="M5.33337 21.334V22.6673C5.33337 24.8765 7.12424 26.6673 9.33337 26.6673H22.6667C24.8759 26.6673 26.6667 24.8765 26.6667 22.6673V21.334M21.3334 16.0007L16 21.334M16 21.334L10.6667 16.0007M16 21.334V5.33398"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export default <Delete />;
