import styled from 'styled-components';

import { colors } from '@src/ui-kit/theme';

export const Card = styled.div`
  height: 225px;
  width: 225px;
  background-color: ${colors.background.grayDark};
  border-radius: 12px;
  padding-top: 27px;
  box-sizing: border-box;
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 23px;
  width: 100%;
`;

export const Eye = styled.img`
  width: 30px;
  height: 30px;
`;

export const Image = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextValue = styled.h1`
  margin: 0;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  width: 100%;
  color: ${colors.text.white};
  margin-top: 23px;
`;

export const Description = styled.p`
  margin: 0;
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  width: 100%;
  padding-top: 21px;
  white-space: pre-line;
  padding-bottom: 15px;
  color: ${colors.text.white};
`;
export const Counter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const Minus = styled.img`
  width: 18.5px;
  height: 18.5px;
  cursor: pointer;
  margin-right: -1px;
`;

export const Plus = styled.img`
  width: 15.24px;
  height: 15.24px;
  cursor: pointer;
`;

export const BoxCount = styled.div`
  width: 54px;
  height: 26px;
  border: 1px solid ${colors.text.white};
  border-radius: 6px;
  color: ${colors.text.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
`;
