import styled from 'styled-components';

import { media, mixins } from '@ui-kit/theme';

import Content from '../../components/content';
import Header from '../../components/header';

export const StyledHeader = styled(Header)`
  height: 96px;
  position: fixed;
  top: 0;
  left: 0;

  ${media.forPhone} {
    height: 72px;
  }
`;
export const StyledContent = styled(Content)`
  height: 100%;
  flex: 1;
  overflow: auto;
  ${mixins.scrolls()};
`;

export const Root = styled.div`
  padding-top: 96px;
  ${mixins.flex({ horizontal: false })}
  flex: 1;
  height: 100%;
  box-sizing: border-box;

  ${media.forPhone} {
    padding-top: 72px;
  }
`;

export const ContentWrapper = styled.div`
  ${mixins.flex({
    horizontal: true,
  })};
  height: 100%;
`;
