import styled from 'styled-components';

import Checkbox, { styles } from '@ui-kit/checkbox';
import { colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  margin-top: 40px;
`;

export const Item = styled.div`
  margin: 10px 0;
  ${typography.regular16};
  color: ${colors.white};
  flex-wrap: nowrap !important;
  flex: 1;

  ${mixins.flex({
    horizontalAlignment: 'space-between',
    verticalAlignment: 'center',
  })};
`;

export const StyledCheckbox = styled(Checkbox)`
  flex-wrap: nowrap !important;
  flex: 1;
  &:hover {
    ${styles.Icon} {
      border-color: ${colors.text.orange};
    }
  }

  ${styles.Label} {
    flex: 1;
  }

  ${styles.Icon} {
    border-color: ${colors.text.white};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;

  svg {
    margin: 0 4px;
    &:hover {
      color: ${colors.text.orange};
      cursor: pointer;
    }
  }
`;
