import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import {
  createPopupPlaceholder,
  destroyPopupPlaceholder,
} from './popup-manager';
import { Popup as Box, PopupProps as PopupStyleProps } from './popup.styles';

export interface PopupProps extends PopupStyleProps {
  isOpened: boolean;
  onClose: () => void;
  hasCloseButton?: boolean;
  children?: ReactNode;
}

export const Popup: FC<PopupProps> = ({
  variant = 'full',
  children,
  isOpened,
  onClose,
  transparentBackground = false,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleEscPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscPress);
    return () => void window.removeEventListener('keydown', handleEscPress);
  }, [onClose]);

  useEffect(() => {
    return () => {
      if (containerRef.current) {
        containerRef.current = null;
        destroyPopupPlaceholder();
      }
    };
  }, []);

  if (isOpened) {
    if (!containerRef.current) {
      containerRef.current = createPopupPlaceholder(
        onClose,
        variant === 'full',
        variant === 'center',
        variant === 'full-screen-window'
      );
    }
  } else {
    if (containerRef.current) {
      containerRef.current = null;
      destroyPopupPlaceholder();
    }
  }

  if (!containerRef.current) {
    return null;
  }

  return createPortal(
    <Box variant={variant} transparentBackground={transparentBackground}>
      {children}
    </Box>,
    containerRef.current
  );
};

export default Popup;
