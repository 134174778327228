import React, { FC } from 'react';

const Menu: FC = () => {
  return (
    <>
      <circle cx="13" cy="8" r="1" fill="currentColor" />
      <circle cx="13" cy="13" r="1" fill="currentColor" />
      <circle cx="13" cy="18" r="1" fill="currentColor" />
    </>
  );
};

export default <Menu />;
