import React, { ChangeEvent, FC, memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateArContext } from '@src/context';
import { SCENARIO } from '@src/core/types';
import FileUploader from '@ui-kit/file-uploader';

import { Input, InputWrapper, Label, Root } from './form-trigger.styles';

interface FormTriggerProps {}

const FileAccept: Record<string, string> = {
  [SCENARIO.BY_IMAGE]: '.jpg,.jpeg,.png',
  [SCENARIO.BY_OBJECT]: '.arobject',
};

export const FormTrigger: FC<FormTriggerProps> = memo((props) => {
  const { onDataItemChange, dataItem, setDisabledNext } =
    useContext(CreateArContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (dataItem?.scenery !== SCENARIO.BY_GEO) {
      setDisabledNext(!dataItem?.triggerFile);
    }
    if (dataItem?.scenery === SCENARIO.BY_GEO) {
      setDisabledNext(
        !dataItem?.triggerLatitude ||
          !dataItem?.triggerLongtitude ||
          !dataItem.triggerLatitude
      );
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDataItemChange((dataItem) => {
      return { ...dataItem, [e.target.name]: e.target.value };
    });
    setDisabledNext(!e.target.value);
  };

  if (dataItem?.scenery === SCENARIO.BY_GEO) {
    return (
      <Root>
        <InputWrapper>
          <Label>Долгота</Label>
          <Input
            onChange={handleChange}
            name="triggerLongtitude"
            type="number"
            defaultValue="0"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Широта</Label>
          <Input
            defaultValue="0"
            onChange={handleChange}
            name="triggerLatitude"
            type="number"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Высота</Label>
          <Input
            defaultValue="0"
            onChange={handleChange}
            name="triggerAltitude"
            type="number"
          />
        </InputWrapper>
      </Root>
    );
  }

  return (
    <Root>
      <FileUploader
        accept={FileAccept[dataItem?.scenery || '']}
        selectedFileName={dataItem?.triggerFile?.name}
        onChange={(ref) => {
          const file = ref.current?.files && ref.current?.files[0];
          onDataItemChange((item) => {
            return {
              ...item,
              triggerFile: file,
            };
          });
          setDisabledNext(!file);
        }}
        description={
          <>
            <p>
              {t('createAr.triggerForm.tip', {
                format: FileAccept[dataItem?.scenery || ''] || '',
              })}
            </p>
            <p>{t(`createAr.triggerForm.tipByType.${dataItem?.scenery}`)}</p>
          </>
        }
      />
    </Root>
  );
});
