import { gql } from '@apollo/client';
import { DataPackage } from '@src/adapters/data-item';

export const createDataPackage = gql`
  mutation (
    $applicationId: Int!
    $name: String!
    $description: String!
    $thumbnailFile: Upload!
    $tags: [String]!
  ) {
    createOneDataPackage(
      data: {
        applicationId: $applicationId
        name: $name
        description: $description
        order: 1
        thumbnailFile: $thumbnailFile
        tags: $tags
      }
    ) {
      id
    }
  }
`;

export interface GetDataPackagesResponse {
  dataPackages: DataPackage[];
}

export const getDataPackages = gql`
  query ($appId: Int!, $userId: Int!) {
    dataPackages(
      where: {
        AND: {
          applicationId: { equals: $appId }
          creator: { userId: { equals: $userId } }
        }
      }
    ) {
      id
      name
      thumbnailPath
      creator {
        id
        user {
          id
          firstname
          lastname
          nickname
          email
        }
      }
    }
  }
`;

export const deleteDataPackage = gql`
  mutation ($dataPackageId: Int!) {
    deleteOneDataPackage(dataPackageId: $dataPackageId) {
      id
    }
  }
`;
export const updateDataPackage = gql`
  mutation (
    $dataPackageId: Int!
    $name: String
    $description: String
    $thumbnailFile: Upload
  ) {
    updateOneDataPackage(
      data: {
        dataPackageId: $dataPackageId
        name: $name
        description: $description
        thumbnailFile: $thumbnailFile
      }
    ) {
      id
    }
  }
`;
