import { useMemo, useRef } from 'react';

const GLOBAL_ID_KEY = '__use_id_store__' + Math.random();
const store: Record<string, number> = {
  [GLOBAL_ID_KEY]: 0,
};

const getId = (prefix?: string): string =>
  (prefix || 'id__') + store[GLOBAL_ID_KEY]++;

export function useId(prefix?: string, providedId?: string): string {
  const ref = useRef<string | undefined>(providedId);
  if (!ref.current) {
    ref.current = getId(prefix);
  }

  return ref.current;
}

let counter = 0;

export const useSvgId = () => {
  const value = useId(`svg~${++counter}`);
  return useMemo(
    () => ({
      id: (index: number) => `${value}~${index}`,
      url: (index: number) => `url(#${value}~${index})`,
    }),
    [value]
  );
};
