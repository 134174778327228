import styled, { css } from 'styled-components';

import InputUi from '@ui-kit/input';
import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  margin-top: 34px;
`;

export const Area = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Column = styled.div<{ selectedDate?: boolean }>`
  width: 5px;
  background-color: #c8c8c8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  min-height: 10;
  margin-right: 5px;
  &:hover {
    background-color: ${colors.accent.yellow};
  }
  ${({ selectedDate }) =>
    selectedDate &&
    css`
      background-color: ${colors.accent.yellow};
    `};
`;

export const TextDate = styled.p`
  white-space: nowrap;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 900;
  color: #ffc506;
  height: 20px;
`;
