import { colors } from './colors';

export const rgba = (hex: string, opacity: number) => {
  let clearedHex = hex;

  if (hex[0] === '#') {
    clearedHex = hex.substr(1);
  }

  const r = parseInt(clearedHex.substr(0, 2), 16);
  const g = parseInt(clearedHex.substr(2, 2), 16);
  const b = parseInt(clearedHex.substr(4, 2), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getSystemColor = (
  type: 'error' | 'warning' | 'info' | 'success'
) => {
  switch (type) {
    case 'error':
      return colors.system.error;
    case 'warning':
      return colors.system.warning;
    case 'success':
      return colors.system.success;
    default:
      return colors.system.info;
  }
};
