import { QRCodeCanvas } from 'qrcode.react';
import React, { FC, memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApplicationsCreators } from '@src/adapters/apps';
import { PLATFORM, SCENARIO } from '@src/core/types';
import { appFormDefaultValues } from '@src/pages';
import ChipsGroup from '@ui-kit/chips-group';
import FileUploader from '@ui-kit/file-uploader';

import {
  ChipsWrapper,
  Input,
  Label,
  Root,
  Row,
  StyledDownloadIcon,
  TextArea,
  Value,
} from './app-form.styles';

interface AppFormProps {
  app?: ApplicationsCreators;
  onSubmit: (formData: AppForm) => void;
}

interface AppForm {
  name: string;
  description: string;
  thumbnail: any;
  iosAppURL: string;
  webAppURL: string;
  androidAppURL: string;
}

export const AppForm: FC<AppFormProps> = memo((props) => {
  const { t } = useTranslation();
  const { app } = props;
  const [qrToDownload, setQrToDownload] = useState<string>('');

  const { register, reset, setValue, control, watch } = useFormContext();
  const [iosAppURL, webAppURL, androidAppURL] = watch([
    'iosAppURL',
    'webAppURL',
    'androidAppURL',
  ]);

  const handleDownloadQr = (name: string) => {
    const link = document.createElement('a');
    link.download = `${name}.png`;
    link.href = (document.getElementById('qrCode') as any)?.toDataURL();
    link.click();
  };

  useEffect(() => {
    if (qrToDownload) {
      handleDownloadQr(qrToDownload);
    }
  }, [qrToDownload]);

  useEffect(() => {
    if (app) {
      reset({
        name: app.name,
        description: app.description,
        thumbnail: null,
        scenaries: app.scenaries,
        platforms: app.platforms,
        iosAppURL: app.iosAppURL,
        webAppURL: app.webAppURL,
        androidAppURL: app.androidAppURL,
      });
    } else {
      reset(appFormDefaultValues);
    }
  }, [app]);

  console.log(' app ', app);

  return (
    <Root>
      {app?.apiURL && (
        <Row>
          <Label>{t('app.apiUrl')}</Label>
          <Value>
            <Input disabled value={app?.apiURL || ''} />
          </Value>
        </Row>
      )}
      <Row>
        <Label>{t('app.name')}</Label>
        <Value>
          <Input {...register('name')} />
        </Value>
      </Row>
      <Row>
        <Label>{t('app.platforms')}</Label>
        <Value>
          <ChipsWrapper>
            <Controller
              name="platforms"
              control={control}
              render={({ field }) => (
                <ChipsGroup
                  items={Object.keys(PLATFORM).map((key: string) => ({
                    value: PLATFORM[key as keyof typeof PLATFORM],
                    label: t(`app.platform.${key.toLowerCase()}`),
                  }))}
                  name={field.name}
                  onChange={(values) => {
                    field.onChange(values);
                  }}
                  value={field.value}
                />
              )}
            />
          </ChipsWrapper>
        </Value>
      </Row>
      <Row>
        <Label>{t('app.sceneries')}</Label>
        <Value>
          <ChipsWrapper>
            <Controller
              name="scenaries"
              control={control}
              render={({ field }) => (
                <ChipsGroup
                  items={Object.keys(SCENARIO).map((key: string) => ({
                    value: SCENARIO[key as keyof typeof SCENARIO],
                    label: t(`common.scenario.${key.toLowerCase()}`),
                  }))}
                  onChange={(values) => {
                    field.onChange(values);
                  }}
                  name={field.name}
                  value={field.value}
                />
              )}
            />
          </ChipsWrapper>
        </Value>
      </Row>
      {!!app?.contentCount && (
        <Row>
          <Label>{t('app.sceneCount')}</Label>
          <Value>
            {!!app?.contentCount && (
              <Label>
                {app?.contentCount} {t('app.count')}
              </Label>
            )}
          </Value>
        </Row>
      )}
      <Row>
        <Label>{t('app.description')}</Label>
        <Value>
          <TextArea {...register('description')} />
        </Value>
      </Row>
      <Row>
        <Label>{t('app.icon')}</Label>
        <Value>
          <FileUploader
            placeholder={`${
              !app?.thumbnailPath ? 'Загрузить' : 'Изменить'
            } иконку приложения 75х75`}
            variant="input"
            onChange={(ref) => {
              const file = ref.current?.files && ref.current?.files[0];
              setValue('thumbnailFile', file, { shouldDirty: true });
            }}
          />
        </Value>
      </Row>

      <Row>
        <Label>{t('app.qrIos')}</Label>
        <Value>
          <Input
            placeholder={t('app.qrIosPlaceholder')}
            {...register('iosAppURL')}
          />
        </Value>
        {iosAppURL && (
          <StyledDownloadIcon
            size={32}
            onClick={() => setQrToDownload(iosAppURL)}
          />
        )}
      </Row>

      <Row>
        <Label>{t('app.qrAndroid')}</Label>
        <Value>
          <Input
            placeholder={t('app.qrAndroidPlaceholder')}
            {...register('androidAppURL')}
          />
        </Value>
        {androidAppURL && (
          <StyledDownloadIcon
            size={32}
            onClick={() => setQrToDownload(androidAppURL)}
          />
        )}
      </Row>

      <Row>
        <Label>{t('app.qrWeb')}</Label>
        <Value>
          <Input
            placeholder={t('app.qrWebPlaceholder')}
            {...register('webAppURL')}
          />
        </Value>
        {webAppURL && (
          <StyledDownloadIcon
            size={32}
            onClick={() => setQrToDownload(webAppURL)}
          />
        )}
      </Row>

      <QRCodeCanvas
        style={{
          display: 'none',
        }}
        id="qrCode"
        size={256}
        value={qrToDownload}
      />
    </Root>
  );
});
