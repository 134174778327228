import React, { FC } from 'react';

const StepCurrent: FC = () => {
  return (
    <>
      <path
        d="M12 9L11.4343 8.43431L12 7.86863L12.5657 8.43431L12 9ZM5.43431 14.4343L11.4343 8.43431L12.5657 9.56569L6.56569 15.5657L5.43431 14.4343ZM12.5657 8.43431L18.5657 14.4343L17.4343 15.5657L11.4343 9.56569L12.5657 8.43431Z"
        fill="#C8C8C8"
      />
    </>
  );
};

export default <StepCurrent />;
