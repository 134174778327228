import styled, { css } from 'styled-components';

import { Switcher as SwitcherUi } from '@ui-kit/switcher';
import { colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
`;

export const Title = styled.h1`
  ${typography.medium24};
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const Container = styled.div`
  border-radius: ${shape.rounding.L};
  height: 360px;
  ${mixins.flex({})};
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
`;

export const ActionsRow = styled.div`
  ${mixins.flex({
    horizontalAlignment: 'space-between',
    verticalAlignment: 'flex-start',
  })};
  margin-bottom: 20px;
`;

export const ColumnMenu = styled.div`
  background: ${colors.background.grayStandard};
  width: 300px;
  padding: 32px;
  box-sizing: border-box;
  color: ${colors.text.white};
`;

export const ColumnContent = styled.div`
  flex: 1;
  height: 100%;
  background: ${colors.background.white};
  padding: 32px;

  ${mixins.flex({
    horizontal: false,
  })};
`;

export const Row = styled.div`
  ${mixins.flex({})};
  margin-bottom: 20px;
`;

export const Label = styled.div`
  width: 30%;
`;
export const Value = styled.div`
  ${typography.bold16};
`;

export const InfoWrapper = styled.div`
  max-width: 540px;
`;

export const Switcher = styled(SwitcherUi)``;

export const LoadingWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })}
`;

export const NavWrapper = styled.div`
  & > *:first-child {
    margin-right: 12px;
  }
`;
