import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@ui-kit/button';

import AuthForm from './components/auth-form';
import RegistrationForm from './components/registration-form';
import { Container, Root, Text, Title } from './home.styles';

interface HomeProps {
  type?: 'signin' | 'signup';
}

export const Home: FC<HomeProps> = memo((props) => {
  const { type = 'signin' } = props;
  const navigate = useNavigate();

  if (type === 'signup') {
    return (
      <Root>
        <RegistrationForm />
      </Root>
    );
  }

  return (
    <Root>
      <Container>
        <Title>Авторизуйтесь для того, чтобы перейти в данный раздел</Title>
        <Text>
          У вас нет учетной записи?
          <br />
          Зарегистрируйтесь
        </Text>
        <Button onClick={() => navigate('/signup')} text="Зарегистрироваться" />
      </Container>
      <AuthForm />
    </Root>
  );
});
