import styled, { css } from 'styled-components';

import InputUi from '@ui-kit/input';
import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div``;

export const Actions = styled.div`
  ${mixins.flex({
    horizontalAlignment: 'center',
  })};
  margin-top: 32px;
  margin-bottom: 62px;
`;

export const Disclaimer = styled.div`
  margin-top: 12px;
  ${typography.regular14};
  color: ${colors.text.grayExtraLight};
`;

export const Input = styled(InputUi)``;

export const SocialWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })};
`;

export const SocialIconWrapper = styled.div<{ isActive?: boolean }>`
  border-radius: 50%;
  margin: 0 4px;
  width: 32px;
  height: 32px;
  background: ${colors.background.yellow};
  color: ${colors.text.black};
  ${mixins.flex({
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  })};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.background.grayDark};
      color: ${colors.text.orange};
    `};
  transition: all ease ${animations.duration};

  &:hover {
    color: ${colors.text.orange};
    background: ${colors.background.grayStandard};
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }
`;

export const Text = styled.div`
  ${typography.regular14};
  color: ${colors.text.black};
  margin-right: 16px;
`;
