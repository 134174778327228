import styled, { css } from 'styled-components';

import Icons from '@ui-kit/icons';
import { animations, mixins } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({
    wrap: true,
  })};

  & > div {
    margin: 0;
    margin-right: 8px;
    margin-bottom: 10px;
  }
`;

export const Label = styled.div``;

export const StyledIcon = styled(Icons.Close)`
  margin-left: 4px;
  transform: rotate(45deg);
  transition: transform ease ${animations.duration};
`;
