import React, { ChangeEvent, FC, memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import '@reach/slider/styles.css';
import { CreateArContext } from '@src/context';
import RangeInput from '@src/pages/create-ar/components/screen-add-item/components/form-settings/components/range-input';

import { Root } from './form-settings.styles';

interface FormTriggerProps {}

interface SettingsForm {
  scale: number;
  offsetX: number;
  offsetY: number;
  offsetZ: number;
}

const parseFloatSafe = (val: any) => {
  const parsedVal = parseFloat(val);
  return isNaN(parsedVal) ? '' : parsedVal;
};

export const FormSettings: FC<FormTriggerProps> = memo((props) => {
  const { onDataItemChange, dataItem, setDisabledNext } =
    useContext(CreateArContext);
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDataItemChange((dataItem) => {
      return { ...dataItem, [e.target.name]: Number(e.target.value) };
    });
  };

  useEffect(() => {
    onDataItemChange({
      scale: 1,
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
      ...dataItem,
    });
  }, []);

  const checkValueCoords = (value: string) => {
    const checkedVal =
      value === '-' || regexCoords.test(value)
        ? value
        : !regexNumber.test(value)
        ? ''
        : Number(value);

    return parseFloatSafe(checkedVal);
  };

  useEffect(() => {
    setDisabledNext(false);
  }, []);

  let regexNumber = new RegExp(/^-?(0|[0-9]\d*)(\.\d*)?$/);
  let regexCoords = new RegExp(/^-?(0\.0*|0*(\.\d+)?|[1-9]\d*(\.\d+)?|\d+\.)$/);

  return (
    <Root>
      <RangeInput
        label={t('createAr.settings.scale')}
        value={dataItem?.scale ? dataItem?.scale?.toString() : ''}
        min={0}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return {
              ...dataItem,
              scale: value.includes('-') ? '' : checkValueCoords(value),
            };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetX')}
        value={dataItem?.offsetX?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem: any) => {
            return { ...dataItem, offsetX: checkValueCoords(value) };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetY')}
        value={dataItem?.offsetY?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, offsetY: checkValueCoords(value) };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetZ')}
        value={dataItem?.offsetZ?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, offsetZ: checkValueCoords(value) };
          });
        }}
      />
    </Root>
  );
});
