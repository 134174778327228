import { toast } from 'react-toastify';

export const arrayFill = ({
  arr = [],
  count,
  value = 1,
}: {
  arr?: any[];
  count: number;
  value?: number;
}) => {
  const result = [...arr];
  result.length = count;
  result.fill(value, 0, count);

  return result;
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const handleResponseError = (
  params: {
    data: any;
    successText?: string;
    errorText?: string;
    type?: string;
  } = {
    data: {},
  }
) => {
  const { type, errorText, successText, data } = params;
  if (type === 'error' || data?.errors?.length > 0) {
    toast.error(errorText || 'Произошла ошибка', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }
  toast.success(successText || 'Операция выполнена успешно', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const dateFormatter = (value: string | null | undefined) =>
  value && value != null ? new Date(value).toLocaleDateString() : '';
