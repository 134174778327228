import { SetStateAction, useCallback, useMemo } from 'react';

export function useNav<T>(
  order: T[],
  current: T,
  onChange: (value: SetStateAction<T>) => void
) {
  const currentIndex = order.findIndex((tab) => tab === current);
  const isNextDisabled = currentIndex >= order.length - 1;
  const isPrevDisabled = currentIndex <= 0;

  const handlePrev = useCallback(() => {
    onChange((current) => {
      if (currentIndex <= 0) {
        return current;
      }

      return order[currentIndex - 1];
    });
  }, [order, onChange, isPrevDisabled, currentIndex]);

  const handleNext = useCallback(() => {
    onChange((current) => {
      if (isNextDisabled) {
        return current;
      }

      return order[currentIndex + 1];
    });
  }, [order, onChange, isNextDisabled, currentIndex]);

  return useMemo(
    () => ({
      currentIndex,
      handlePrev,
      handleNext,
      isNextDisabled,
      isPrevDisabled,
    }),
    [currentIndex, handlePrev, handleNext, isNextDisabled, isPrevDisabled]
  );
}
