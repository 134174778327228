import React, { FC } from 'react';

const Chart: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.95"
        y="8.45"
        width="4.1"
        height="9.1"
        rx="1.55"
        stroke="currentColor"
        stroke-width="0.9"
      />
      <rect
        x="7.95"
        y="5.45"
        width="4.1"
        height="12.1"
        rx="1.55"
        stroke="currentColor"
        stroke-width="0.9"
      />
      <rect
        x="13.95"
        y="2.45"
        width="4.1"
        height="15.1"
        rx="1.55"
        stroke="currentColor"
        stroke-width="0.9"
      />
    </svg>
  );
};

export default <Chart />;
