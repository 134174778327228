import React, { FC } from 'react';

const Delete: FC = () => {
  return (
    <>
      <path
        d="M8.33398 12.5L8.33398 10"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M11.666 12.5L11.666 10"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2.5 5.83301H17.5V5.83301C17.0353 5.83301 16.803 5.83301 16.6098 5.87144C15.8164 6.02925 15.1962 6.64945 15.0384 7.44283C15 7.63603 15 7.86835 15 8.33301V12.6663C15 14.552 15 15.4948 14.4142 16.0806C13.8284 16.6663 12.8856 16.6663 11 16.6663H9C7.11438 16.6663 6.17157 16.6663 5.58579 16.0806C5 15.4948 5 14.552 5 12.6663V8.33301C5 7.86835 5 7.63603 4.96157 7.44283C4.80376 6.64945 4.18356 6.02925 3.39018 5.87144C3.19698 5.83301 2.96466 5.83301 2.5 5.83301V5.83301Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8.38947 2.80883C8.48443 2.72023 8.69367 2.64194 8.98474 2.5861C9.27582 2.53027 9.63246 2.5 9.99935 2.5C10.3662 2.5 10.7229 2.53027 11.014 2.5861C11.305 2.64194 11.5143 2.72023 11.6092 2.80883"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </>
  );
};

export default <Delete />;
