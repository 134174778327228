export const colors = {
  primary: '#016EF2',
  primaryHover: '#0E6AF3',
  primaryActive: '#1443d6',

  text: {
    black: '#000',
    gray: '#3A3A3A',
    grayDark: '#CDD9E9',
    grayExtraLight: '#C8C8C8',
    dark: '#6E7A88',
    white: '#FFFFFF',
    blue: '#016EF2',
    blueLight: '#60759A',
    green: '#3DA17E',
    red: '#D8313D',
    warning: '#ffa300',
    orange: '#fcc506',
  },

  background: {
    grayExtraDark: '#070707',
    grayDark: '#2D2D2D',
    grayStandard: '#3A3A3A',
    grayExtraLight: '#C8C8C8',
    grayLight: '#EFEFEF',
    orange: '#fd6e06',
    white: '#FFFFFF',
    darkStandard: '#37434D',
    darkLevel1: '#242F38',
    darkLevel2: '#293540',
    warning: '#fff1de',
    yellow: '#fcc506',
    green: '#0BA886',
    purple: '#D731E6',
    menuItemCounter: '#7E99B0',
    hover: {
      grayStandard: '#DDE5F1',
      darkStandard: '#37434D',
      grayDark: '#6E7A88',
    },
    brown: '#2d2d2d',
    brownLight: '#969696',
  },

  dividers: {
    grayDark: '#CDD9E9',
    grayStandard: '#DDE5F1',
    darkStandard: '#646464',
  },

  accent: {
    blue: '#016EF2',
    green: '#11AB33',
    yellow: '#FFC506',
    red: '#FF0000',
    buy: '#55B852',
    sell: '#D8313D',
    orange: '#f43117',
  },
  /*  'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'*/
  system: {
    primary: '#0d6efd',
    secondary: '#6c757d',
    success: '#198754',
    danger: '#dc3545',
    error: '#dc3545',
    warning: '#ffc107',
    info: '#0dcaf0',
    light: '#f8f9fa',
    dark: '#212529',
  },

  white: '#FFFFFF',
  gray95: '#f2f2f2',
  gray85: '#d9d9d9',
  gray75: '#bfbfbf',
  gray65: '#a6a6a6',
  gray55: '#8c8c8c',
  gray45: '#737373',
  gray35: '#595959',
  gray25: '#404040',
  gray15: '#262626',
  gray5: '#0d0d0d',
  black: '#000000',

  svg: {
    color: { darkStandard: '#6e7a88', grayStandard: '#768ca9' },
    hover: { black: '#000', white: '#fff' },
  },

  button: {
    background: {
      grayStandard: '#f4f7fa',
      darkStandard: '#37434D',
    },
    hover: {
      grayStandard: '#E6EDF4',
      darkStandard: '#6E7A88',
    },
    color: {
      grayStandard: '#768CA9',
      darkStandard: '#DDE5F1',
    },
  },

  input: {
    background: {
      light: '#fff',
      dark: '#37434D',
    },
  },

  border: {
    light: '#DDE5F1',
    dark: '#2D3A46',
    grayStandard: '#F4F7FA',
    darkStandard: '#1D2730',
    white: '#FFF',
  },

  states: {
    button: {
      focusOutline: '#85D7F2',
      filled: {
        default: '#016EF2',
        hover: '#0E5AF3',
        press: '#1443D6',
        disable: '#E6EDF4',
      },
      outline: {
        default: '#CDD9E9',
        hover: '#DDE5F1',
        press: '#B8C6D9',
        disable: '#CDD9E9',
      },
    },
    uploader: {
      border: '#DDE5F1',
    },
    input: {
      default: '#CDD9E9',
      hover: '#DDE5F1',
      focus: '#2B65D9',
      focusOutline: '#85D7F2',
      error: '#E62E4D',
      errorFocus: '#E62E4D',
      errorFocusOutline: '#E62E4D',
      disableBackground: '#F4F7FA',
    },
    checkbox: {
      onDefaultBackground: '#016EF2',
      onHoverBackground: '#1764FF',
      onPressBackground: '#1443D6',
      onDisableBackground: '#DDE5F1',
      offDefault: '#CDD9E9',
      offHover: '#DDE5F1',
      offFocus: '#B8C6D9',
      offDisable: '#CDD9E9',
      disableLabelColor: '#9FAEC3',
    },
    card: {
      border: '#CDD9E9',
    },
  },
};
