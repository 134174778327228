import React, { FC, memo } from 'react';
import { TailSpin } from 'react-loader-spinner';

import Button from '@ui-kit/button';
import Popup from '@ui-kit/popup';
import { colors } from '@ui-kit/theme';

import { DialogWindow } from '../dialog-window';
import { ActionsWrapper } from './confirmation.dialog.styles';

interface ConfirmationDialogProps {
  title?: string;
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = memo((props) => {
  const { isOpened, onClose, onConfirm, title, loading } = props;

  return (
    <Popup variant="full" isOpened={isOpened} onClose={onClose}>
      <DialogWindow
        title={title || 'Вы действительно хотите удалить элемент?'}
        onClose={onClose}
      >
        <ActionsWrapper>
          {!loading && (
            <Button variant="outline" text="OK" onClick={onConfirm} />
          )}
          {loading && (
            <TailSpin
              height="32"
              width="32"
              color={colors.background.yellow}
              ariaLabel="loading"
            />
          )}
          <Button variant="dark" text="Отмена" onClick={onClose} />
        </ActionsWrapper>
      </DialogWindow>
    </Popup>
  );
});
