import styled, { css } from 'styled-components';

import InputUi from '@ui-kit/input';
import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`

`;

export const BoxValue = styled.div`
  min-width: 115px;
  height: 24px;
  border-radius: 6px;
  color: ${colors.background.grayDark};
  border: 1px solid ${colors.text.grayExtraLight};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`;

const tableStyles = css`
  border-collapse: collapse;
  width: 100%;
  margin-top: 24px;

  th,
  td {
    border: 1px solid #efefef;
    text-align: left;

    border-top: 0;
  }

  th {
    font-weight: 400;
    text-align: center;
  }

  td {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 400;
  }

  td:not(:first-child) {
    font-weight: 600;
  }

  tr td:first-child {
    text-align: start;
  }

  tr:first-child th {
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
    border-top: 0;
  }

  tr th:first-child {
    border-bottom: 0;
    border-right: 0;
  }

  tr th:nth-child(2) {
    border-left: 0;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
`;

export const StyledTable = styled.table`
  ${tableStyles}
`;

export const Line = styled.div`
  margin-top: 27px;
  color: #2d2d2d;
`;
