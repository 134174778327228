import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { mutation } from '@src/adapters';
import { DataPackage } from '@src/adapters/data-item';
import { handleResponseError } from '@src/core/utils';

export function useDataPackage() {
  const [dataPackageToDelete, setDataPackageToDelete] = useState<
    DataPackage | undefined
  >();
  const [dataPackageToEdit, setDataPackageToEdit] = useState<
    DataPackage | undefined
  >();

  const [mutateDelete, resultMutateDelete] = useMutation(
    mutation.dataPackage.deleteDataPackage,
    {
      onCompleted: (data) => {
        handleResponseError({
          data,
        });
      },
      onError: (data) => {
        handleResponseError();
      },
    }
  );

  const [mutateEdit, resultMutateEdit] = useMutation(
    mutation.dataPackage.updateDataPackage,
    {
      onCompleted: () => {},
    }
  );

  return {
    mutateDelete,
    mutateEdit,
    resultMutateEdit,
    resultMutateDelete,

    dataPackageToDelete,
    setDataPackageToDelete,
    dataPackageToEdit,
    setDataPackageToEdit,
  };
}
