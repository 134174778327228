import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import { Root, Text, Title } from './faq.styles';

interface FaqProps {}

export const Faq: FC<FaqProps> = memo(() => {
  const { question } = useParams();

  const answer = (question && faqAnswers[Number(question) - 1]) || undefined;

  if (!answer) {
    return null;
  }

  return (
    <Root>
      <Title>{answer.title}</Title>
      <Text>{answer.text}</Text>
    </Root>
  );
});

const faqAnswers = [
  {
    title: 'Что такое AR, и чем полезна эта технология?',
    text: (
      <>
        <b>Дополненная реальность</b> — (англ. «augmented reality»: AR)
        результат введения в зрительное поле любых сенсорных данных с целью
        дополнения сведений об окружении и изменения восприятия окружающей
        среды.
        <br />
        <br />
        <br />
        Потенциальное применение технологии для креативного сектора:
        <ul>
          <li>
            Сопровождение продукции предприятий AR-инструкцией. Позволяет кратно
            увеличивать лояльность среди корпоративных клиентов и/или целевых
            потребителей.
          </li>
          <li>
            Сопровождение AR для корпоративных информационных материалов
            предприятий. Позволяет увеличить эффективность проводимых мер
            менеджмента изменений среди сотрудников предприятий.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Что такое ARGOS, и для чего мне это?',
    text: (
      <>
        <b>Дополненная реальность</b> — (англ. «augmented reality»: AR)
        результат введения в зрительное поле любых сенсорных данных с целью
        дополнения сведений об окружении и изменения восприятия окружающей
      </>
    ),
  },
  {
    title: 'Подробнее про сценарий «Изображение»',
    text: (
      <>
        <b>Подробнее про сценарий «Изображение»</b> — (англ. «augmented
        reality»: AR) результат введения в зрительное поле любых сенсорных
        данных с целью дополнения сведений об окружении и изменения восприятия
        окружающей
      </>
    ),
  },
  {
    title: 'Подробнее про сценарий «Объект»',
    text: (
      <>
        <b>Подробнее про сценарий «Объект»</b> — (англ. «augmented reality»: AR)
        результат введения в зрительное поле любых сенсорных данных с целью
        дополнения сведений об окружении и изменения восприятия окружающей
      </>
    ),
  },
  {
    title: 'Подробнее про сценарий «Локация»',
    text: (
      <>
        <b>Подробнее про сценарий «Локация»</b> — (англ. «augmented reality»:
        AR) результат введения в зрительное поле любых сенсорных данных с целью
        дополнения сведений об окружении и изменения восприятия окружающей
      </>
    ),
  },
  {
    title: 'Подробнее про сценарий «Часть тела»',
    text: (
      <>
        <b>Подробнее про сценарий «Часть тела»</b> — (англ. «augmented reality»:
        AR) результат введения в зрительное поле любых сенсорных данных с целью
        дополнения сведений об окружении и изменения восприятия окружающей
      </>
    ),
  },
];
