import {
  APP_TYPE,
  CREATE_AR_STEP,
  ITEM_TAB,
  ITEM_TYPE,
  SCENARIO,
} from '@src/core/types';

export default {
  common: {
    scenario: {
      [SCENARIO.BY_BODYPART]: 'Face',
      [SCENARIO.BY_IMAGE]: 'Image',
      [SCENARIO.BY_GEO]: 'Geo',
      [SCENARIO.BY_HERO]: 'Hiro mark',
      [SCENARIO.BY_OBJECT]: 'Object',
      [SCENARIO.IN_SPACE]: 'Space',
    },
    type: {
      [ITEM_TYPE.IMAGE]: 'Image',
      [ITEM_TYPE.VIDEO]: 'Video',
      [ITEM_TYPE.OBJECT]: '3D object',
      [ITEM_TYPE.WIDGET]: 'Widget',
    },
    next: 'Next',
    back: 'Back',
    save: 'Save changes',
    create: 'Create',
    notFound: 'Elements not found',
    logout: 'Logout',
    connectApp: 'Connect App',
    continue: 'Keep going',
    menu: {
      createAr: 'Create AR',
      media: 'Media Library',
      library: 'AR library',
      faq: 'FAQ',
    },
    arSceneInfo: 'Information AR-scene',
    alphaChannel: 'Alpha channel',
    selectFile: 'Select file',
    download: 'Download',
  },
  media: {
    distribution: 'Distribution',
    dataPackage: 'Data package',
    arScenario: 'AR scenario',
    content: 'Content',
  },
  createAr: {
    selectScenario: {
      tip: 'Reviving images from the Daily Prophet [see below] Harry Potter] - as an image for recognition, use the first frame from the video to display in the AR scene. \n\n The video played over the image must match the aspect ratio of the image - then you will see the real magic!',
      title: {
        [SCENARIO.BY_IMAGE]: 'Image',
        [SCENARIO.BY_OBJECT]: 'Object',
        [SCENARIO.BY_GEO]: 'Geo',
        [SCENARIO.BY_BODYPART]: 'Body Parts',
        [SCENARIO.IN_SPACE]: 'Space',
      },
      description: {
        [SCENARIO.BY_IMAGE]: 'Image description',
        [SCENARIO.BY_OBJECT]: 'Objects description',
        [SCENARIO.BY_GEO]: 'Geo description',
        [SCENARIO.BY_BODYPART]: 'Body parts description',
        [SCENARIO.IN_SPACE]: 'Space description',
      },
    },
    selectApp: {
      action: 'Select application',
      actionDescription:
        'Detailed information about the selected application is displayed here',
      saveChoice: 'Remember choice for next session',

      title: {
        [APP_TYPE.PRIVATE]: 'Access via private operator',
        [APP_TYPE.PUBLIC]: 'Access via public operator',
        [APP_TYPE.EXPORT]: 'File conversion and upload',
      },
      info: {
        [APP_TYPE.EXPORT]:
          'After creating the AR scene, you will be prompted to choose the format of the uploaded data. \n\n This distribution option is suitable for those users who plan to independently store and administer data for their mobile tools on their server.',
      },
      content: {
        [APP_TYPE.PRIVATE]: '',
        [APP_TYPE.PUBLIC]: '',
        [APP_TYPE.EXPORT]:
          'You can download a single archive with all formats or choose one of the options for a specific hardware and software base: \n\n iOS app / Android app / Web app',
      },
      tip: {
        [APP_TYPE.PUBLIC]:
          'Choose the right operator for your content from the list of publicly available ones. \n\n You can use your tools to aggregate custom AR content.',
        [APP_TYPE.PRIVATE]:
          'Want the exclusive right to create AR content for your app? Please! \n\n You can connect your private tools to replicate incredible AR scenes. Try to specialize different tools according to different AR usage tasks.',
        [APP_TYPE.EXPORT]:
          'Go ahead, create AR! \n Read the rules for using uploaded data - [FAQ]',
      },
    },
    addItem: {
      title: {
        [ITEM_TAB.TRIGGER]: 'Define trigger',
        [ITEM_TAB.UPLOAD]: 'Upload content to display',
        [ITEM_TAB.SETTINGS]: 'Adjust content display',
        [ITEM_TAB.DATA_PACKAGE]: 'Select a data package',
        [ITEM_TAB.NAME]: 'Identify an AR scene',
      },
      tip: {
        [ITEM_TAB.TRIGGER]:
          'The Living Pictures from the Daily Prophet [cf. Harry Potter] - as an image for recognition, use the first frame from the video to display in the AR scene. \n\n The video played over the image must match the aspect ratio of the image - then you will see the real magic!',
        [ITEM_TAB.SETTINGS]: '',
        [ITEM_TAB.NAME]: '',
        [ITEM_TAB.DATA_PACKAGE]: '',
        [ITEM_TAB.UPLOAD]: '',
      },
      content: {
        empty: 'Customizable AR scene displayed here',
      },
      trigger: {
        widgetLabel: 'Enter link',
      },
    },

    context: {
      info: {
        [CREATE_AR_STEP.APP]: {
          label: 'Select AR Content Distribution Option',
          value:
            'Before creating AR, it is important to choose a content publishing strategy. \n\n Already connected your application? If not, which operator is right for you?',
        },
        [CREATE_AR_STEP.SCENARIO]: {
          label: 'Define AR display script',
          value:
            'The number of available scenes is determined by the capabilities of the selected operator. \n\n Explore a new perspective and you will succeed in creating AR!',
        },
        [CREATE_AR_STEP.SETTINGS]: {
          label: 'Customize the rendered AR scene',
          value:
            'Depending on the selected scenario, perform the content setup procedure. \n\n As a result, publish or upload the content according to the selected distribution option.',
        },
      },
    },

    createSuccess: 'Ar scene edited successfully',
    editSuccess: 'Ar scene edited successfully',
    createAr: 'Create AR',
    successAction: 'Go to Library',

    loadingConvertationTitle: 'Files are being converted and loaded',
    loadingConversionDescription:
      "The process may take several minutes. Please don't leave the page",

    triggerForm: {
      tip: 'File format : {{ format, string }}',
      tipByType: {
        by_image:
          'Recommended image resolution is at least 1000px x 1000px on your server',
        by_object: 'First, read the guide for preparing this format [FAQ]',
      },
    },
    contentForm: {
      tip: 'Received data format: {{ format, string }}',
      extraTip: 'Add content and set additional options',
      tipByType: {
        video:
          'Select the option if the video contains a chrome key - the RGB color to be removed: [0;255;0]',
        image:
          'Add an interactive hyperlink to go to third-party web resources by clicking on the image',
      },
    },
    settings: {
      scale: 'Scale',
      offsetX: 'X axis',
      offsetY: 'Y axis',
      offsetZ: 'Z axis',
    },
  },
  me: {
    publicApp: 'Your public apps',
    privateApp: 'Your private apps',
    profile: {
      title: 'User Data',
      linkedSocial: 'Linked socials network',
      save: 'Update data',
      disclaimer:
        'Fill in user data or link social networks to an existing account to quickly enter the portal',
    },
  },
  app: {
    apiUrl: 'Reference key',
    name: 'Name',
    operator: 'Operator',
    platforms: 'Platforms',
    sceneries: 'Scenarios',
    sceneCount: 'AR count',
    description: 'Description',
    createdAt: 'Created at',
    file: 'Download link',
    icon: 'Icon',
    count: 'AR scenes',
    platform: {
      ios: 'iOS app',
      android: 'android app',
      web: 'web app',
    },
    qrIos: 'QR on iOS',
    qrAndroid: 'QR on Android',
    qrWeb: 'QR to Web',

    qrIosPlaceholder: 'Add Web Download Link',
    qrAndroidPlaceholder: 'Add Web Download Link',
    qrWebPlaceholder: 'Add web download link',
  },
  sign: {
    welcome: 'Welcome to ARGOS',
    welcomeBack: 'Welcome back to ARGOS',
    login: 'login',
    email: 'Email',
    name: 'Name',
    password: 'Password',
    passwordRepeat: 'Repeat password',
    signUp: 'Sign Up',
    backToSignIn: 'Back to Sign In',
    signInVia: 'Sign in via',
    signIn: 'Sign In',
  },
  payment: {
    balance: 'Balance',
    views: 'views',
    entityData: 'Legal entity data',
    viewsInBasket: 'Number of impressions in cart',
    paySumm: 'Amount to be paid:',
    rub: 'rub',
    IAccept: 'I accept',
    tariff: 'tariff offer',
    bill: 'Bill',
    ukassa: 'Pay use ',
    ecosystem: 'ecosystem ZRENIE 2.0',
    units: 'u',
    oneViews: '1 view  = 1 rub',
    addBalance: 'Top up your balance for the specified number of impressions',
    descriptionsCard: {
      personal: 'for personal use',
      creative: 'for creative experiments',
      big: 'for large-scale projects',
    },
    dataEntity: {
      name: 'Name of the legal entity',
      inn: 'INN',
      address: 'Legal address',
      phone: 'Phone',
      email: 'Email (optional)',
      declare: 'Enter your email to receive an additional invoice by email',
      btnTitle: 'Bill',
    },
  },
  stat: {
    analitic: 'Impression analytics',
    all: 'Total',
    s: ' from ',
    on: ' in ',
    app: 'app',
    browser: 'web brawser',
    last30: 'Over the last 30 days:',
  },
};
