//import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div``;

export const InputWrapper = styled.div`
  color: ${colors.text.white};
  ${mixins.flex({
    verticalAlignment: 'center',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 24px;
  height: 24px;
`;

export const Label = styled.div`
  margin-right: 12px;
  width: 30%;
  text-align: right;

  ${typography.regular16};
`;

export const RangeWrapper = styled.div`
  width: 80px;
  margin-left: 16px;
`;
export const Input = styled.input`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.text.white};
  background: none;
  padding: 4px;
  width: 50px;
  box-sizing: border-box;

  color: ${colors.text.white};

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
