import React, { FC, ReactNode, memo } from 'react';

import { Root } from './content.styles';

interface ContentProps {
  className?: string;
  children?: ReactNode;
}

export const Content: FC<ContentProps> = memo(({ className, children }) => {
  return <Root className={className}>{children}</Root>;
});
