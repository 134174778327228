import React, { FC } from 'react';

const StepCurrent: FC = () => {
  return (
    <>
      <circle cx="9" cy="9" r="4" fill="currentColor" />
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="8"
        stroke="currentColor"
        strokeWidth="2"
      />
    </>
  );
};

export default <StepCurrent />;
