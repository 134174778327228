import styled from 'styled-components';

import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${typography.regular14};
  ${mixins.flex({
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  })};
  background: ${colors.background.grayStandard};
  padding: 4px 16px;
  border-radius: ${shape.rounding.M};
  color: ${colors.text.white};
  cursor: pointer;

  & > * {
    margin: 0 4px;
    transition: all ease ${animations.duration};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: ${colors.text.orange};
    }
  }
`;
