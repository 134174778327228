import React, { FC, ReactNode, memo } from 'react';

import { Accordion } from '@reach/accordion';

import {
  Root,
  StyledAccordionButton,
  StyledAccordionItem,
  StyledAccordionPanel,
  StyledArrowIcon,
} from './accordion.styles';

interface AccordionProps {
  items: { item: ReactNode; panel: ReactNode }[];
}

export const UiAccordion: FC<AccordionProps> = memo((props) => {
  const { items } = props;
  return (
    <Root>
      <Accordion collapsible multiple>
        {items.map(({ item, panel }, i) => (
          <StyledAccordionItem key={`accordion-item-${i}`}>
            <StyledAccordionButton>
              {item}
              <StyledArrowIcon size={24} />
            </StyledAccordionButton>
            <StyledAccordionPanel>{panel}</StyledAccordionPanel>
          </StyledAccordionItem>
        ))}
      </Accordion>
    </Root>
  );
});
