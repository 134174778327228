import React, { FC } from 'react';

const Vk: FC = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0801 20.1109C18.0801 18.3529 19.6255 18.9391 20.6727 19.9216C21.7199 20.9041 22.9408 22.4314 23.5888 23.0588C24.2369 23.6863 24.5609 24 25.5329 24C26.3665 24 27.801 24 28.7731 24C30.0691 24 30.7171 23.6863 28.7731 21.1765C27.0508 18.9531 24.8433 17.0232 24.3619 16.6116C24.3088 16.5663 24.3037 16.4876 24.3488 16.4344C25.5922 14.9684 26.8074 13.0427 28.1721 10.6204C29.4715 8.31375 28.7728 8.00069 28.1721 8.00069C27.4767 8.0005 26.0622 8.00031 25.5327 8.00031C24.2366 8.00025 24.0882 8.62956 23.9126 8.94119C21.969 12.3914 20.9388 13.7596 19.7768 14.7274C19.2663 15.1527 18.7627 15.4371 18.1587 15.6123C18.1192 15.6237 18.0801 15.5956 18.0801 15.5544C18.0801 15.0038 18.0797 11.7323 18.0801 9.56875C18.0804 8 17.7564 8.00056 16.4603 8.00056C15.4883 8.00044 14.1661 8.00025 13.2202 8.00025C11.9241 8.00081 11.6936 8.71794 12.2482 9.25488C12.8027 9.79188 12.8959 10.4285 12.8959 11.1372C12.8959 12.8371 12.8959 16.6278 12.8959 17.347C12.8959 17.3976 12.8391 17.4261 12.7981 17.3965C12.0582 16.8622 11.3792 16.1844 10.7439 15.3789C9.28006 13.5228 8.17156 11.1376 7.22262 8.6875C7.1055 8.385 6.73994 8 5.76791 8C4.79587 8.00013 3.54081 8.00031 2.85161 8.00019C2.20356 8.00019 1.58157 8.41794 2.36853 10.45C3.36368 13.0195 4.68532 15.9676 6.62362 18.4253C8.59087 20.9197 11.0319 23.1613 14.8405 23.6863C15.9147 23.8344 17.5293 23.7284 17.9675 23.6953C18.0322 23.6904 18.0806 23.6365 18.0806 23.5716L18.0801 20.1109Z"
        fill="currentColor"
      />
    </>
  );
};

export default <Vk />;
