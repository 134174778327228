import React, { FC, memo } from 'react';

import Icons from '@ui-kit/icons';

import { Root } from './hover-menu.styles';

interface HoverMenuProps {
  onClick: (type?: string) => void;
  isShowEdit?: boolean;
  isShowDelete?: boolean;
}

export const HoverMenu: FC<HoverMenuProps> = memo((props) => {
  const { onClick, isShowEdit = true, isShowDelete = true } = props;

  return (
    <Root>
      <Icons.ViewsInMenu onClick={() => onClick('views')} size={20} />
      <Icons.Chart onClick={() => onClick('chart')} size={20} />
      {isShowEdit && <Icons.Edit onClick={() => onClick('edit')} size={20} />}
      {isShowDelete && (
        <Icons.Delete onClick={() => onClick('delete')} size={20} />
      )}
    </Root>
  );
});
