import styled from 'styled-components';

import { Checkbox } from '@ui-kit/checkbox';
import { colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div``;

export const Form = styled.form`
  ${mixins.flex({ horizontal: false })}

  & > * {
    margin-bottom: 12px;
  }
`;

export const Title = styled.div`
  ${typography.bold16};
  color: ${colors.text.white};
  margin-bottom: 16px;
`;

export const Select = styled.select`
  width: 100%;
  background: none;
  border: 1px solid ${colors.text.white};
  padding: 8px 12px;
  color: ${colors.text.white};
  border-radius: ${shape.rounding.S};
`;

export const Option = styled.option``;

export const TypeWrapper = styled.div`
  margin-bottom: 32px;
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  ${typography.regular12};
  margin-top: 12px;
`;

export const InputWrapper = styled.div`
  color: ${colors.text.white};
  width: 100%;

  margin-bottom: 32px;
  margin-top: 32px;
`;

export const Label = styled.div`
  margin-right: 12px;
  text-align: left;

  ${typography.regular12};
`;

export const Input = styled.input`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.text.white};
  background: none;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px;

  color: ${colors.text.white};
`;
