import React, {
  FC,
  MutableRefObject,
  ReactNode,
  memo,
  useRef,
  useState,
} from 'react';

import Icons from '@ui-kit/icons';

import {
  Description,
  Input,
  InputContainer,
  InputWrapper,
  Preview,
  Root,
  Text,
  TextInner,
} from './file-uploader.styles';

export type Variant = 'input' | 'default';
export type ColorVariant = 'light' | 'dark';

interface FileUploaderProps {
  className?: string;
  description?: ReactNode;
  onChange?: (fileRef: MutableRefObject<HTMLInputElement | null>) => void;
  selectedFileName?: string;
  imageUrl?: string;
  variant?: Variant;
  colorVariant?: ColorVariant;
  placeholder?: string;
  accept?: string;
}

export const FileUploader: FC<FileUploaderProps> = memo((props) => {
  const {
    className,
    description,
    onChange,
    selectedFileName,
    imageUrl,
    variant = 'default',
    colorVariant = 'light',
    placeholder,
    accept,
  } = props;
  const [selectedFile, setSelectedFile] = useState<string | undefined>(
    selectedFileName
  );
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined>();

  const ref = useRef<HTMLInputElement | null>(null);
  const handleChange = () => {
    let fileName: string | string[] = (ref.current?.value ?? '').split('\\');
    fileName = fileName[fileName.length - 1];

    const typeFromTypeField = (ref.current?.files?.[0]?.type || '').split(
      '/'
    )?.[1];
    // for .glb etc
    const typeFromNameChunks = (ref.current?.files?.[0]?.name || '').split('.');
    const typeFromName = typeFromNameChunks[typeFromNameChunks.length - 1];
    const type = typeFromTypeField || typeFromName;

    if (accept && !type) {
      return;
    }
    if (type && accept && accept.indexOf(type) === -1) {
      return;
    }

    setSelectedFile(fileName || undefined);
    if (onChange) {
      onChange(ref);
    }

    if (ref.current?.files?.[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(ref.current?.files?.[0]);
      reader.onload = (e) => {
        if (e?.target?.result) {
          setSelectedFileUrl(e?.target?.result as string);
        }
      };
    }
  };

  const showPreview = variant === 'input' && !!selectedFileUrl;

  return (
    <Root
      colorVariant={colorVariant}
      variant={variant}
      className={className}
      selectedFile={!!selectedFile}
    >
      <InputWrapper>
        <InputContainer showPreview={showPreview} imageUrl={imageUrl}>
          {!selectedFile && !placeholder && <Icons.Upload size={24} />}
          {(selectedFile || placeholder) && (
            <Text>
              <TextInner>{selectedFile || placeholder}</TextInner>
            </Text>
          )}

          <Input
            accept={accept}
            ref={ref}
            onChange={handleChange}
            type="file"
          />
        </InputContainer>
        {showPreview && <Preview imageUrl={selectedFileUrl} />}
      </InputWrapper>

      {description && <Description>{description}</Description>}
    </Root>
  );
});
