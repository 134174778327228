import { gql } from '@apollo/client';

export const me = gql`
  query Me {
    me {
      id
      firstname
      avararUrl
      lastname
      nickname
      email
      createdAt
      vkId
      googleId
      balance {
        id
        current_balance
      }
      contributions {
        id
        application {
          id
          name
        }
      }
    }
  }
`;

export interface Contribution {
  id: string;
  application: {
    id: string;
    name: string;
  };
}

// enum TRANSACTION_TYPE {
//   'WITHDRAWAL',
//   'ENROLLMENT',
// }
// export interface Transaction {
//   id: number;
//   amount: number;
//   balance_id: number;
//   created: Date;
//   transaction_type: TRANSACTION_TYPE;
//   balance: Balance;
// }
export interface Balance {
  id: number;
  current_balance: number;
}

export interface Me {
  id: number;
  firstname: string;
  balance: {
    id: number;
    current_balance: number;
  };
  avararUrl: string;
  lastname: string;
  nickname: string;
  email: string;
  createdAt: string;
  vkId?: string;
  googleId?: string;
  contributions?: Contribution[];
}

export interface User extends Me {}

export const updateMe = gql`
  mutation (
    $firstname: String
    $lastname: String
    $email: String
    $password: String
  ) {
    updateMe(
      data: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
      }
    ) {
      id
    }
  }
`;
