import { gql } from '@apollo/client';

export const getApps = gql`
  query {
    availableApplications {
      id
      name
      apiURL
      scenaries
      platforms
      isPublic

      description
      thumbnailPath
      iosAppURL
      androidAppURL
      webAppURL

      contentCount
    }
  }
`;

export interface DataItemDto {
  id: number;
  name: string;
  triggerType: string;
  thumbnailPath: string;
}

export interface User {
  email?: string;
}

export interface ContentCreator {
  id: number;
  user: User;
  dataItems: DataItemDto[];
  dataPackages: {
    id: number;
    name: string;
    description?: string;
    thumbnailPath?: string;
    tags: string[];
  }[];
}

export interface Application {
  id: number;
  name: string;
  apiURL: string;
  scenaries: string[];
  platforms: string[];
  isPublic: boolean;

  contentCount?: number;
  thumbnailPath?: string;

  iosAppURL?: string;
  webAppURL?: string;
  androidAppURL?: string;

  contentCreators?: ContentCreator[];
}

export interface PublicAppsResponse {
  applications: Application[];
}
export interface AvailableAppsResponse {
  availableApplications: Application[];
}

export interface ApplicationsCreators {
  id: number;
  name: string;
  isPublic?: boolean;

  apiURL: string;
  scenaries?: string[];
  description: string;
  platforms?: string[];
  contentCount?: number;
  thumbnailPath?: string;

  iosAppURL?: string;
  webAppURL?: string;
  androidAppURL?: string;

  contentCreators: ContentCreator[];
}

export const list = gql`
  query ($userId: Int!) {
    applications(
      where: {
        OR: [
          { creatorId: { equals: $userId } }
          { contentCreators: { some: { userId: { equals: $userId } } } }
        ]
      }
    ) {
      id
      name
      isPublic
      apiURL
      scenaries
      platforms
      description

      contentCreators(where: { user: { id: { equals: $userId } } }) {
        id
        user {
          nickname
          lastname
          id
        }

        dataPackages {
          id
          name
          thumbnailPath
          description
          tags
        }
      }
    }
  }
`;

export const listMeAuthor = gql`
  query ($userId: Int!) {
    applications(where: { creatorId: { equals: $userId } }) {
      id
      name
      isPublic
      apiURL
      scenaries
      platforms
      description
      thumbnailPath
      iosAppURL
      androidAppURL
      webAppURL
      contentCount
    }
  }
`;

export const updateApp = gql`
  mutation (
    $name: String
    $description: String
    $id: Int!
    $platforms: [String]
    $scenaries: [String]
    $thumbnailFile: Upload
    $androidAppURL: String
    $iosAppURL: String
    $webAppURL: String
  ) {
    UpdateOneApplication(
      data: {
        name: $name
        description: $description
        id: $id
        platforms: $platforms
        scenaries: $scenaries
        thumbnailFile: $thumbnailFile
        androidAppURL: $androidAppURL
        iosAppURL: $iosAppURL
        webAppURL: $webAppURL
      }
    ) {
      id
    }
  }
`;

export const createApplication = gql`
  mutation (
    $name: String!
    $description: String!
    $platforms: [String]!
    $scenaries: [String]!
    $thumbnailFile: Upload
    $androidAppURL: String
    $isPublic: Boolean!
  ) {
    CreateOneApplication(
      data: {
        name: $name
        description: $description
        platforms: $platforms
        scenaries: $scenaries
        thumbnailFile: $thumbnailFile
        androidAppURL: $androidAppURL
        isPublic: $isPublic
      }
    ) {
      id
    }
  }
`;
