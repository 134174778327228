import React, { FC, memo } from 'react';

import { Item, Root } from './bread-crumbs.styles';

interface BreadCrumbsProps {
  items: { label: string; value: string }[];
  className?: string;
}

export const BreadCrumbs: FC<BreadCrumbsProps> = memo((props) => {
  const { items, className } = props;

  return (
    <Root className={className}>
      {items.map(({ label, value }) => (
        <Item key={`breadcrumb-${value}`}>{label}</Item>
      ))}
    </Root>
  );
});
