import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { mutation, queries } from '@src/adapters';
import { DataItem, MyDataItemsResponse } from '@src/adapters/data-item';
import { Me } from '@src/adapters/me';
import { ConfirmationDialog } from '@src/components/dialog';
import HoverMenu from '@src/components/hover-menu';
import ModalStatistics from '@src/components/modal-statistics';
import QrCode from '@src/components/qr-code';
import { MediaFiltersContext } from '@src/context';
import { InfoLoading, TableLoading } from '@src/pages/media/media.loading';
import Icons from '@ui-kit/icons';
import Table from '@ui-kit/table';
import Thumbnail from '@ui-kit/thumbnail';

import {
  HoverMenuWrapper,
  Label,
  Root,
  Row,
  SecondaryTitle,
  StyledMenuIcon,
  SubTitle,
  Switcher,
  Title,
  Value,
} from './media.styles';
import { getInfo } from './media.utils';

interface CreateArProps {}

enum TABS {
  INFO = 'info',
  QR = 'qr',
}

const map: Record<string, string> = {
  android: 'androidAppURL',
  ios: 'iosAppURL',
  web: 'webAppURL',
};

const getLinkPath = (platform: string) => {
  return map[platform] as
    | 'androidAppURL'
    | 'iosAppURL'
    | 'webAppURL'
    | undefined;
};

export const Media: FC<CreateArProps> = memo(() => {
  const [tab, setTab] = useState('info');
  const { filters } = useContext(MediaFiltersContext);
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<DataItem | undefined>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const navigate = useNavigate();

  const [showModalStatistics, setShowModalStatistics] = useState<number | null>(
    null
  );

  const [itemToDelete, setItemToDelete] = useState<DataItem | undefined>();

  const { data: meResponse } = useQuery<{
    me: Me;
  }>(queries.user.me, { fetchPolicy: 'network-only' });

  const {
    loading: loadingData,
    data,
    refetch,
  } = useQuery<MyDataItemsResponse>(queries.dataItem.getDataItems, {
    variables: {
      contributorId:
        meResponse &&
        meResponse.me.contributions &&
        meResponse.me.contributions.length > 0
          ? meResponse.me.contributions.map(({ id }) => id)
          : [3],
      ...(filters.app.length && { apps: filters.app.map((id) => Number(id)) }),
      dataPackage: filters.dataPackage.length
        ? filters.dataPackage.map((dataPackageId) => Number(dataPackageId))
        : undefined,
      triggerType: filters.scenario.length ? filters.scenario : undefined,
      itemType: filters.itemType.length ? filters.itemType : undefined,
    },
    onCompleted: (data1) => {
      setSelectedItem(
        data1.dataItems.length > 0 ? data1.dataItems[0] : undefined
      );
    },
    fetchPolicy: 'no-cache',
  });

  const [mutateDelete, resultMutateDelete] = useMutation(
    mutation.dataItem.deleteDataItem,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const loading = resultMutateDelete.loading || loadingData;

  const items = data?.dataItems ?? [];
  const infoItems =
    (selectedItem?.contributor?.application && getInfo(selectedItem, t)) || [];

  const handleRowClick = useCallback(
    (rowIndex: number) => {
      const selectedItem = items[rowIndex];
      setTab(TABS.INFO);
      setSelectedItemIndex(rowIndex);
      setSelectedItem(selectedItem);
    },
    [items]
  );

  const handleHoverElClick = useCallback(
    (rowIndex: number, type?: string) => {
      const selectedItem = items[rowIndex];
      setTab(TABS.INFO);
      if (type === 'delete') {
        setItemToDelete(selectedItem);
      }
      if (type === 'edit') {
        navigate(`/edit-ar/${selectedItem.id}`);
      }
      if (type === 'chart') {
        setShowModalStatistics(selectedItem.id);
      }
    },
    [items]
  );

  const handleConfirmDelete = () => {
    mutateDelete({ variables: { dataItemId: itemToDelete?.id } });
    setItemToDelete(undefined);
  };

  const info = useMemo(() => {
    switch (tab) {
      case TABS.INFO:
        return (
          <>
            {!loading && (
              <>
                {infoItems.map((info, i) => (
                  <Row key={`${i}-${info.label}-info`}>
                    <Label>{info.label}</Label>
                    <Value>{info.value}</Value>
                  </Row>
                ))}
              </>
            )}
            {loading && <InfoLoading />}
            {!loading && !items.length && <Title>{t('common.notFound')}</Title>}
          </>
        );
      case TABS.QR:
        return (
          <>
            <SubTitle>
              Проверьте данную AR-сцену через инструменты оператора
            </SubTitle>
            {(selectedItem?.lsEntityData || [])
              ?.map((internalItem) => {
                const linkPath = getLinkPath(internalItem.platform);
                if (!linkPath) {
                  return null;
                }
                const link = selectedItem?.contributor?.application?.[linkPath];
                if (!link) {
                  return null;
                }

                return (
                  <Row key={`info-${internalItem.platform}`}>
                    <Label>{internalItem.platform}</Label>
                    <Value>
                      <QrCode value={`${link}/${internalItem.id}`} />
                    </Value>
                  </Row>
                );
              })
              .filter(Boolean)}
          </>
        );
    }
  }, [loading, infoItems, tab]);

  return (
    <Root>
      <Title>{t('common.arSceneInfo')}</Title>

      <Switcher
        tabs={[
          { value: 'info', content: <Icons.Clipboard size={32} /> },
          { value: 'qr', content: <Icons.Qr size={24} /> },
        ]}
        info={info}
        content={
          <>
            {!loading && items && items.length > 0 && (
              <Table
                activeIndex={selectedItemIndex}
                onRowClick={handleRowClick}
                headers={TableHeaders}
                rows={items.map(
                  ({ name, thumbnailPath, triggerType, itemType }, rowI) => ({
                    columns: [
                      { content: <Thumbnail src={thumbnailPath} /> },
                      { content: name },
                      { content: t(`common.scenario.${triggerType}`) },
                      { content: t(`common.type.${itemType}`) },
                      {
                        content: (
                          <HoverMenuWrapper>
                            <HoverMenu
                              onClick={(type) => handleHoverElClick(rowI, type)}
                            />
                            <StyledMenuIcon size={26} />
                          </HoverMenuWrapper>
                        ),
                      },
                    ],
                  })
                )}
                // hoverElement={HoverMenu}
                // onHoverElClick={handleHoverElClick}
              />
            )}
            {loading && <TableLoading />}
            {!loading && !items.length && (
              <SecondaryTitle>{t('common.notFound')}</SecondaryTitle>
            )}
          </>
        }
        currentTab={tab}
        onTabChange={setTab}
      />

      <ConfirmationDialog
        isOpened={!!itemToDelete}
        onConfirm={handleConfirmDelete}
        onClose={() => setItemToDelete(undefined)}
      />

      <ModalStatistics
        id={showModalStatistics}
        onClose={() => setShowModalStatistics(null)}
      />
    </Root>
  );
});

const TableHeaders = {
  columns: [
    { content: undefined },
    { content: 'Название' },
    { content: 'Сценарий AR' },
    { content: 'Тип элемента' },
    { content: undefined },
  ],
};
