import React, { FC, ReactElement, ReactNode, memo } from 'react';

import Sidebar from '../../components/sidebar';
import {
  ContentWrapper,
  Root,
  StyledContent,
  StyledHeader,
} from './layout.styles';

interface LayoutProps {
  children?: ReactElement;
  withSidebar?: boolean;
  sideBar?: ReactNode;
  withWrapper?: boolean;
}

export const Layout: FC<LayoutProps> = memo(({ children, ...props }) => {
  const { withSidebar = true, sideBar, withWrapper } = props;

  return (
    <Root>
      <StyledHeader />
      <ContentWrapper>
        {withSidebar && (
          <>
            <Sidebar>{sideBar}</Sidebar>
          </>
        )}
        {!withSidebar && !withWrapper && children}
        {withWrapper && <StyledContent>{children}</StyledContent>}
      </ContentWrapper>
    </Root>
  );
});
