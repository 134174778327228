import React, { FC, memo } from 'react';

import { Root } from './avatar.styles';

interface AvatarProps {
  name?: string;
  className?: string;
}

export const Avatar: FC<AvatarProps> = memo((props) => {
  const { name, className } = props;

  return <Root className={className}>{name && name[0]}</Root>;
});
