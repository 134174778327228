import styled from 'styled-components';

import { colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div``;

export const Form = styled.form`
  ${mixins.flex({ horizontal: false })}

  & > * {
    margin-bottom: 12px;
  }
`;

export const Input = styled.input`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.text.white};
  background: none;
  padding: 4px;
  flex: 1;
  box-sizing: border-box;
  text-align: center;

  color: ${colors.text.white};
`;
export const InputWrapper = styled.div`
  color: ${colors.text.white};
  ${mixins.flex({
    verticalAlignment: 'center',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 24px;
  height: 24px;
`;

export const Label = styled.div`
  margin-right: 12px;
  width: 30%;
  text-align: right;

  ${typography.regular16};
`;
