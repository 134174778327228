import styled, { css } from 'styled-components';

import Icons from '@ui-kit/icons';
import { colors, mixins, shape, typography } from '@ui-kit/theme';

import { DialogSize } from './dialog-window';

export const Root = styled.div<{ size: DialogSize }>`
  padding: 32px 40px;
  background: ${colors.background.white};
  border-radius: ${shape.rounding.L};
  position: relative;
  box-sizing: border-box;
  width: 460px;

  ${({ size }) =>
    size === 's' &&
    css`
      width: 340px;
    `};
`;

export const Title = styled.div`
  ${typography.bold18};
  margin-bottom: 32px;
  text-align: center;
`;

export const StyledCloseIcon = styled(Icons.Close)`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${colors.text.gray};
  cursor: pointer;
`;
