import React, { forwardRef, ForwardRefRenderFunction, memo } from 'react';

export interface IconWrapperProps extends React.SVGAttributes<SVGSVGElement> {
  size?: number;
}

export const createIcon = (
  iconName: string,
  path: JSX.Element,
  svgProps?: React.SVGAttributes<SVGSVGElement>,
  fill: string = 'currentColor'
) => {
  const Icon: ForwardRefRenderFunction<SVGSVGElement, IconWrapperProps> = (
    { size, ...props },
    ref
  ) => {
    return (
      <svg
        width={size ? size : '100%'}
        height={size ? size : '100%'}
        viewBox="0 0 16 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...svgProps}
        {...props}
        data-component={`${iconName}-icon`}
      >
        {path}
      </svg>
    );
  };
  Icon.displayName = iconName;

  return memo(forwardRef(Icon));
};
