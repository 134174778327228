import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { mutation, queries } from '@src/adapters';
import { ApplicationsCreators } from '@src/adapters/apps';
import { ProfileEditDialog } from '@src/components/dialog';
import { AuthContext } from '@src/context';
import { InfoRadio } from '@src/pages/create-ar/components/info-radio';
import { AppForm } from '@src/pages/me/components/app-form';
import { Avatar } from '@ui-kit/avatar';
import Button from '@ui-kit/button';
import Icons from '@ui-kit/icons';
import { colors } from '@ui-kit/theme';

import {
  ActionsWrapper,
  Column,
  Profile,
  ProfileName,
  Root,
  StyledEditIcon,
  StyledSwitcher,
  Title,
} from './me.styles';

interface MePageProps {}

enum TABS {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

interface AppForm {
  name: string;
  description: string;
  thumbnailFile?: any;
  scenaries?: string[];
  platforms?: string[];
  iosAppURL?: string;
  webAppURL?: string;
  androidAppURL?: string;
}
const appFormSchema = Yup.object<AppForm>({
  name: Yup.string().required(),
  description: Yup.string().required(),
});
export const appFormDefaultValues = {
  name: '',
  description: '',
  thumbnailFile: null,
  scenaries: [],
  platforms: [],
  iosAppURL: '',
  webAppURL: '',
  androidAppURL: '',
};

const onSuccess = (type: 'create' | 'edit') => {
  toast.success(
    type === 'create' ? 'Приложение создано' : 'Приложение успешно обновлено',
    {
      position: toast.POSITION.TOP_RIGHT,
    }
  );
};

const onError = () => {
  toast.error('Что то пошло не так', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const Me: FC<MePageProps> = memo((props) => {
  const [currentTab, setCurrentTab] = useState<string>(TABS.PUBLIC);
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const [selectedApp, setSelectedApp] = useState<
    ApplicationsCreators | undefined
  >();
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [mutateUpdate, resultMutateUpdate] = useMutation(
    mutation.app.updateApp
  );
  const [mutateCreate, resultMutateCreate] = useMutation(
    mutation.app.createApplication
  );

  const LINK_APP = useMemo(
    () => ({
      label: t('common.connectApp'),
      id: 0,
      isEmpty: true,
    }),
    [t]
  );

  const methods = useForm<AppForm>({
    resolver: yupResolver(appFormSchema),
    mode: 'all',
    defaultValues: appFormDefaultValues,
  });
  const thumbnail = methods.watch('thumbnailFile');

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleAppChange = useCallback(
    (app: any) => {
      if (selectedApp) {
        mutateUpdate({
          variables: {
            ...app,
            id: selectedApp.id,
          },
          onCompleted: () => {
            onSuccess('edit');
            refetch();
          },
          onError,
        });
      } else {
        mutateCreate({
          variables: {
            isPublic: currentTab === TABS.PUBLIC,
            ...app,
          },
          onCompleted: () => {
            onSuccess('create');
            refetch();
          },
          onError,
        });
      }
    },
    [selectedApp, currentTab]
  );

  const isLoading = resultMutateUpdate.loading || resultMutateCreate.loading;

  const { data, refetch } = useQuery<{
    applications: ApplicationsCreators[];
  }>(queries.app.listMeAuthor, {
    variables: {
      userId: user?.id,
    },
  });

  const publicApps = useMemo(
    () => data?.applications?.filter(({ isPublic }) => isPublic),
    [data]
  );
  const privateApps = useMemo(
    () => data?.applications?.filter(({ isPublic }) => !isPublic),
    [data]
  );

  const appsItems = useMemo(() => {
    const apps = currentTab === TABS.PUBLIC ? publicApps : privateApps;

    return (
      <InfoRadio
        initialValue={0}
        items={[
          LINK_APP,
          ...(apps || []).map((app) => ({
            id: app.id,
            label: app.name,
            thumbnail: app.thumbnailPath,
          })),
        ]}
        onChange={(item) =>
          setSelectedApp(
            item ? apps?.find(({ id }) => id === item.id) : undefined
          )
        }
      />
    );
  }, [currentTab, publicApps, privateApps, selectedApp]);

  return (
    <Root>
      {user && (
        <Profile>
          <Column>
            <Avatar name={user.firstname ?? ''} />
            <ProfileName>
              {user.firstname} {user.lastname}
            </ProfileName>
            <StyledEditIcon
              onClick={() => setShowProfileDialog(true)}
              size={32}
            />
          </Column>
          <Column>
            <Button
              onClick={handleLogout}
              variant="outline"
              text={t('common.logout')}
            />
          </Column>
        </Profile>
      )}

      <Title>
        {currentTab === TABS.PUBLIC && t('me.publicApp')}
        {currentTab === TABS.PRIVATE && t('me.privateApp')}
      </Title>

      <StyledSwitcher
        tabs={[
          {
            value: TABS.PUBLIC,
            content: <Icons.Public size={32} />,
          },
          {
            value: TABS.PRIVATE,
            content: <Icons.Private size={32} />,
          },
        ]}
        info={appsItems}
        content={
          <FormProvider {...methods}>
            <AppForm onSubmit={handleAppChange} app={selectedApp} />
          </FormProvider>
        }
        currentTab={currentTab}
        onTabChange={(value: any) => {
          setCurrentTab(value);
          setSelectedApp(undefined);
        }}
      />
      <ActionsWrapper>
        {!isLoading && (
          <Button
            text={t(selectedApp ? t('common.save') : t('common.create'))}
            disabled={!methods.formState.isValid || !methods.formState.isDirty}
            variant="dark"
            onClick={methods.handleSubmit(handleAppChange)}
          />
        )}
        {isLoading && (
          <TailSpin
            height="32"
            width="32"
            color={colors.background.yellow}
            ariaLabel="loading"
          />
        )}
      </ActionsWrapper>

      <ProfileEditDialog
        isOpened={showProfileDialog}
        onClose={() => setShowProfileDialog(false)}
      />
    </Root>
  );
});
