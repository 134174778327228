import { FC, PropsWithChildren, createContext, memo, useState } from 'react';

export const MediaFiltersContext = createContext<{
  filters: {
    app: string[];
    dataPackage: string[];
    scenario: string[];
    itemType: string[];
  };
  onFilterChange: (filterName: string, value: boolean, name?: string) => void;
}>({
  filters: {
    app: [],
    dataPackage: [],
    scenario: [],
    itemType: [],
  },
  onFilterChange: () => {},
});

interface MediaFiltersProviderProps extends PropsWithChildren {}

export const MediaFiltersProvider: FC<MediaFiltersProviderProps> = memo(
  ({ children }) => {
    const [filters, setFilters] = useState<{
      app: string[];
      dataPackage: string[];
      scenario: string[];
      itemType: string[];
    }>({
      app: [],
      dataPackage: [],
      scenario: [],
      itemType: [],
    });

    const handleChange = (
      filterName: string,
      value: boolean,
      name?: string
    ) => {
      setFilters((prev) => {
        if (!name) return prev;
        const currentFilterValue = prev[filterName as keyof typeof prev];
        const nextFilterValue =
          currentFilterValue.indexOf(name) > -1
            ? currentFilterValue.filter((item) => item !== name)
            : [...currentFilterValue, name];

        return {
          ...prev,
          [filterName]: nextFilterValue,
        };
      });
    };

    return (
      <MediaFiltersContext.Provider
        value={{
          filters,
          onFilterChange: handleChange,
        }}
      >
        {children}
      </MediaFiltersContext.Provider>
    );
  }
);
