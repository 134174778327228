import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import styled from 'styled-components';

import Icons from '../icons';
import { animations, colors, mixins, typography } from '../theme';

export const Root = styled.div``;

export const StyledArrowIcon = styled(Icons.Arrow)`
  transform: rotate(180deg);
  transition: transform ${animations.duration};
  margin-left: 4px;
`;

export const StyledAccordionButton = styled(AccordionButton)`
  background: none;
  ${typography.bold18};
  color: ${colors.text.white};
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  ${mixins.flex({})}

  &[data-state='open'] {
    ${StyledArrowIcon} {
      transform: rotate(360deg);
    }
  }
`;

export const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: 32px;
`;

export const StyledAccordionPanel = styled(AccordionPanel)`
  margin-top: 20px;
`;
