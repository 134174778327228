import styled from 'styled-components';

import { mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
  height: 100%;
  max-width: 520px;
  padding: 0 56px;
`;

export const Title = styled.h1`
  ${typography.medium24};
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const Text = styled.div`
  ${typography.regular16};

  li {
    margin-bottom: 24px;
  }
`;
