import styled from 'styled-components';

import { BreadCrumbs } from '@ui-kit/bread-crumbs';
import { mixins, typography } from '@ui-kit/theme';

export const StyledBreadCrumbs = styled(BreadCrumbs)`
  margin-top: -20px;
`;

export const Title = styled.div`
  ${typography.bold32};
  margin-bottom: 24px;
`;

export const Text = styled.div`
  ${typography.bold24};
  // margin-bottom: 24px;
`;

export const LoadingWrapper = styled.div`
  // margin-top: 52px;
  margin-bottom: 20px;
  margin-right: 32px;
`;

export const Loading = styled.div`
  ${mixins.flex({})}
`;

export const Actions = styled.div`
  margin-top: 64px;

  & > * {
    &:first-child {
      margin-right: 16px;
    }
  }
`;
