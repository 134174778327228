import styled from 'styled-components';

import { mixins } from '@ui-kit/theme';

export const Root = styled.div`
  width: 325px;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  overflow: auto;
  max-height: 100%;
  ${mixins.scrolls()};
`;
