import { TFunction } from 'react-i18next';

import { DataItem } from '@src/adapters/data-item';
import Thumbnail from '@ui-kit/thumbnail';

export const getInfo = (item: DataItem, t: TFunction) => {
  const application = item.contributor?.application;

  return [
    {
      label: t('app.name'),
      value: item.name,
    },
    {
      label: t('app.operator'),
      value: application?.name,
    },
    {
      label: t('app.platforms'),
      value: application?.platforms?.map((platform) => (
        <>
          {platform}
          <br />
        </>
      )),
    },
    {
      label: t('media.dataPackage'),
      value: item.dataPackage?.name,
    },
    {
      label: t('app.description'),
      value: item.description,
    },
    {
      label: t('app.createdAt'),
      value: item.createdAt && new Date(item.createdAt).toDateString(),
    },
    {
      label: t('app.file'),
      value: (
        <a href={item.filePath} target="_blank">
          {t('common.download')}
        </a>
      ),
    },
  ];
};
