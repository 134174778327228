import React, { FC } from 'react';

const Qr: FC = () => {
  return (
    <>
      <path
        d="M0 25.9999H11.8181V14.1816H0V25.9999ZM2.36345 16.5455H9.45444V23.6181H2.36345V16.5455Z"
        fill="currentColor"
      />
      <path
        d="M7.09209 18.9092H4.72852V21.2731H7.09209V18.9092Z"
        fill="currentColor"
      />
      <path
        d="M21.2724 23.6357H18.9082V25.9994H21.2724V23.6357Z"
        fill="currentColor"
      />
      <path
        d="M25.9989 23.6357H23.6348V25.9994H25.9989V23.6357Z"
        fill="currentColor"
      />
      <path
        d="M23.6343 16.5455H21.2709V14.1816H14.1797V25.9999H16.5435V18.9089H18.9068V21.2728H25.998V14.1816H23.6343V16.5455Z"
        fill="currentColor"
      />
      <path
        d="M0 11.8184H11.8181V0H0V11.8184ZM2.36345 2.36345H9.45444V9.45444H2.36345V2.36345Z"
        fill="currentColor"
      />
      <path
        d="M7.09209 4.72754H4.72852V7.09132H7.09209V4.72754Z"
        fill="currentColor"
      />
      <path
        d="M14.1797 0V11.8184H25.9982V0H14.1797ZM23.6343 9.45444H16.5435V2.36345H23.6343V9.45444Z"
        fill="currentColor"
      />
      <path
        d="M21.2724 4.72754H18.9082V7.09132H21.2724V4.72754Z"
        fill="currentColor"
      />
    </>
  );
};

export default <Qr />;
