import memoizeOne from 'memoize-one';

import AdjustmentsSvgPath from './icons/adjustments';
import Adjustments from './icons/adjustments';
import ArrowSvgPath from './icons/arrow';
import ArrowLeftSvgPath from './icons/arrow-left';
import ByBodypartSvgPath from './icons/by-bodypart';
import ByBodypart from './icons/by-bodypart';
import ByGeoSvgPath from './icons/by-geo';
import ByGeo from './icons/by-geo';
import ByImageSvgPath from './icons/by-image';
import ByObjectSvgPath from './icons/by-object';
import ByObject from './icons/by-object';
import BySpaceSvgPath from './icons/by-space';
import BySpace from './icons/by-space';
import ChartSvg from './icons/chart';
import ClipboardSvgPath from './icons/clipboard';
import CloseSvgPath from './icons/close';
import CubeSvgPath from './icons/cube';
import DatabaseSvgPath from './icons/database';
import DeleteSvgPath from './icons/delete';
import DownloadSvgPath from './icons/download';
import EditSvgPath from './icons/edit';
import ExportSvgPath from './icons/export';
import GoogleSvgPath from './icons/google';
import InformationSvgPath from './icons/information';
import LocaleSvgPath from './icons/locale';
import MenuSvgPath from './icons/menu';
import PlaySvgPath from './icons/play';
import PrivateSvgPath from './icons/private';
import PublicSvgPath from './icons/public';
import QrSvgPath from './icons/qr';
import StepCurrentSvgPath from './icons/step-current';
import StepDoneSvgPath from './icons/step-done';
import StepEmptySvgPath from './icons/step-empty';
import TipSvgPath from './icons/tip';
import UploadPath from './icons/upload';
import ViewsInMenuSvg from './icons/viewsInMenu';
import VkSvgPath from './icons/vk';
import { IconWrapperProps as IconProps, createIcon } from './utils/create-icon';

const memoizedCreateIcon = memoizeOne(createIcon);
const size16SvgProps = { viewBox: '0 0 16 16' };
const size18SvgProps = { viewBox: '0 0 18 18' };
const size20SvgProps = { viewBox: '0 0 20 20' };
const size24SvgProps = { viewBox: '0 0 24 24' };
const size26SvgProps = { viewBox: '0 0 26 26' };
const size30SvgProps = { viewBox: '0 0 30 30' };
const size32SvgProps = { viewBox: '0 0 32 32' };

const Icons = {
  get Tip() {
    return memoizedCreateIcon('Tip', TipSvgPath, size32SvgProps);
  },
  get StepCurrent() {
    return memoizedCreateIcon('StepCurrent', StepCurrentSvgPath, {
      ...size18SvgProps,
      fill: 'none',
    });
  },
  get StepDone() {
    return memoizedCreateIcon('StepDone', StepDoneSvgPath, size18SvgProps);
  },
  get StepEmpty() {
    return memoizedCreateIcon('StepEmpty', StepEmptySvgPath, {
      ...size18SvgProps,
      fill: 'none',
    });
  },
  get Arrow() {
    return memoizedCreateIcon('Arrow', ArrowSvgPath, size24SvgProps);
  },
  get Export() {
    return memoizedCreateIcon('Export', ExportSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Private() {
    return memoizedCreateIcon('Private', PrivateSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Public() {
    return memoizedCreateIcon('Public', PublicSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Clipboard() {
    return memoizedCreateIcon('Clipboard', ClipboardSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Qr() {
    return memoizedCreateIcon('Qr', QrSvgPath, {
      ...{ viewBox: '0 0 26 26' },
      fill: 'none',
    });
  },
  get ByImage() {
    return memoizedCreateIcon('ByImage', ByImageSvgPath, {
      ...{ viewBox: '0 0 26 26' },
      fill: 'none',
    });
  },
  get ByObject() {
    return memoizedCreateIcon('ByObject', ByObjectSvgPath, {
      ...{ viewBox: '0 0 26 26' },
      fill: 'none',
    });
  },
  get ByGeo() {
    return memoizedCreateIcon('ByGeo', ByGeoSvgPath, {
      ...{ viewBox: '0 0 22 30' },
      fill: 'none',
    });
  },
  get ByBodypart() {
    return memoizedCreateIcon('ByBodypart', ByBodypartSvgPath, {
      ...{ viewBox: '0 0 20 30' },
      fill: 'none',
    });
  },
  get BySpace() {
    return memoizedCreateIcon('BySpace', BySpaceSvgPath, {
      ...{ viewBox: '0 0 28 28' },
      fill: 'none',
    });
  },

  get Cube() {
    return memoizedCreateIcon('Cube', CubeSvgPath, {
      ...size24SvgProps,
    });
  },
  get Play() {
    return memoizedCreateIcon('Play', PlaySvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Adjustments() {
    return memoizedCreateIcon('Adjustments', AdjustmentsSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Information() {
    return memoizedCreateIcon('Information', InformationSvgPath, {
      ...size24SvgProps,
    });
  },
  get Database() {
    return memoizedCreateIcon('Database', DatabaseSvgPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Upload() {
    return memoizedCreateIcon('Upload', UploadPath, {
      ...size24SvgProps,
      fill: 'none',
    });
  },
  get Delete() {
    return memoizedCreateIcon('Delete', DeleteSvgPath, {
      ...size20SvgProps,
      fill: 'none',
    });
  },
  get Edit() {
    return memoizedCreateIcon('Edit', EditSvgPath, {
      ...size20SvgProps,
      fill: 'none',
    });
  },
  get Close() {
    return memoizedCreateIcon('Close', CloseSvgPath, {
      ...size30SvgProps,
      fill: 'none',
    });
  },
  get ViewsInMenu() {
    return memoizedCreateIcon('ViewsInMenu', ViewsInMenuSvg, {
      ...size20SvgProps,
      fill: 'none',
    });
  },
  get Chart() {
    return memoizedCreateIcon('Chart', ChartSvg, {
      ...size20SvgProps,
      fill: 'none',
    });
  },
  get Vk() {
    return memoizedCreateIcon('Vk', VkSvgPath, {
      ...size32SvgProps,
      fill: 'none',
    });
  },
  get Google() {
    return memoizedCreateIcon('Google', GoogleSvgPath, {
      ...size32SvgProps,
      fill: 'none',
    });
  },
  get Menu() {
    return memoizedCreateIcon('Menu', MenuSvgPath, {
      ...size26SvgProps,
      fill: 'none',
    });
  },
  get ArrowLeft() {
    return memoizedCreateIcon('ArrowLeft', ArrowLeftSvgPath, {
      viewBox: '0 0 19 15',
      fill: 'none',
    });
  },
  get Locale() {
    return memoizedCreateIcon('Locale', LocaleSvgPath, {
      viewBox: '0 0 20 20',
      fill: 'none',
    });
  },
  get Download() {
    return memoizedCreateIcon('Download', DownloadSvgPath, {
      viewBox: '0 0 32 32',
      fill: 'none',
    });
  },
};

export type { IconProps };
export default Icons;
