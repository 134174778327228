import React, { FC, PropsWithChildren, memo } from 'react';

import { Root, StyledCloseIcon, Title } from './dialog-window.styles';

export type DialogSize = 's' | 'm' | 'l';

interface DialogWindowProps {
  title?: string;
  onClose?: () => void;
  size?: DialogSize;
  onClick?: () => void;
}

export const DialogWindow: FC<PropsWithChildren<DialogWindowProps>> = memo(
  (props) => {
    const { children, title, onClose, size = 'm', onClick } = props;

    return (
      <Root size={size} onClick={onClick}>
        <StyledCloseIcon size={30} onClick={onClose} />
        {!!title && <Title>{title}</Title>}
        {children}
      </Root>
    );
  }
);
