import React, { FC } from 'react';

const StepDone: FC = () => {
  return (
    <>
      <rect width="18" height="18" rx="9" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8838 6.88398L8.88377 12.884C8.41844 13.3493 7.67201 13.3742 7.17676 12.9408L3.17676 9.44081L4.82302 7.55937L7.94292 10.2893L13.116 5.11621L14.8838 6.88398Z"
        fill="#2D2D2D"
      />
    </>
  );
};

export default <StepDone />;
