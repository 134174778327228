import { createUploadLink } from 'apollo-upload-client';
import React, { FC } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { install } from 'resize-observer';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthProvider } from '@src/context';
import '@src/i18n/config';

import Routes from '../routes';
import './root.css';

if (!window.ResizeObserver) install();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const user = window.localStorage.getItem('user');

  return {
    headers: {
      ...headers,
      ...(user && { authorization: user }),
    },
  };
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
});

export interface RootProps {}

export const Root: FC<RootProps> = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Routes />
        <ToastContainer />
      </AuthProvider>
    </ApolloProvider>
  );
};
