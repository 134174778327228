import React, { FC, memo } from 'react';

import { Root } from './thumbnail.styles';

interface ThumbnailProps {
  src?: string;
  className?: string;
}

export const Thumbnail: FC<ThumbnailProps> = memo((props) => {
  const { src, className } = props;

  const handleClick = () => {
    if (src) {
      window.open(src);
    }
  };

  return <Root src={src} className={className} onClick={handleClick} />;
});
