import styled, { css } from 'styled-components';

import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

export const Input = styled.input`
  ${typography.regular14};
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.background.grayExtraLight};
  background: none;
  padding: 6px 16px;
  box-sizing: border-box;
  flex: 1;
  transition: all ease ${animations.duration};
  width: 100%;

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;

export const Label = styled.div`
  margin-right: 12px;
  width: 30%;
  text-align: left;

  ${typography.regular16};
`;

export const InputWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 24px;
`;

export const InputMessage = styled.div`
  ${typography.regular12};
  margin-top: 4px;
  margin-left: 12px;
`;

export const InputContainer = styled.div<{ error?: boolean }>`
  width: 100%;
  margin-bottom: 12px;

  ${({ error }) =>
    error &&
    css`
      color: ${colors.text.red};
      input {
        border-color: ${colors.text.red};
      }
    `}
`;
