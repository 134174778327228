import styled, { css } from 'styled-components';

import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

import { ColorVariant, Variant } from './file-uploader';

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
`;

export const Description = styled.div`
  ${typography.regular16};
  margin-top: 40px;
`;

export const InputWrapper = styled.div`
  ${mixins.flex({
    wrap: false,
  })}
`;

export const InputContainer = styled.div<{
  imageUrl?: string;
  showPreview?: boolean;
}>`
  width: 100%;

  ${({ showPreview }) =>
    showPreview &&
    css`
      max-width: 290px;
    `}

  position: relative;
  border-radius: ${shape.rounding.M};
  border: 2px dashed ${colors.text.white};
  height: 120px;
  margin-bottom: 12px;
  padding: 12px;
  box-sizing: border-box;
  transition: all ease ${animations.duration};

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url('${imageUrl}');
    `}

  &:hover {
    border-color: ${colors.accent.yellow};
    color: ${colors.accent.yellow};
  }

  ${mixins.flex({
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
  })};
`;

export const Preview = styled.div<{ imageUrl?: string }>`
  width: 32px;
  height: 32px;
  margin-left: 16px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${shape.rounding.S};
  overflow: hidden;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url('${imageUrl}');
    `};
`;

export const Text = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  max-height: 100%;
`;
export const TextInner = styled.div`
  max-width: 100%;
  max-height: 100%;
`;

export const Root = styled.div<{
  colorVariant?: ColorVariant;
  variant?: Variant;
  selectedFile?: boolean;
}>`
  color: ${colors.text.white};

  ${({ colorVariant }) =>
    colorVariant === 'dark' &&
    css`
      ${InputContainer} {
        border-color: ${colors.background.grayExtraLight};
        color: ${colors.text.gray};

        &:hover {
          color: ${colors.text.gray};
          border-color: ${colors.background.grayDark};
        }
      }
    `}

  ${({ variant, selectedFile }) =>
    variant === 'input' &&
    css`
      color: ${colors.text.gray};

      ${InputContainer} {
        border: 1px solid ${colors.background.grayExtraLight};
        height: 32px;
        padding: 4px 8px;
        border-radius: ${shape.rounding.S};

        ${selectedFile &&
        css`
          background: ${colors.background.grayDark};
          border-color: ${colors.background.grayDark};
          ${TextInner} {
            color: #fff;
          }
        `}

        ${Text} {
          max-width: 80%;

          ${TextInner} {
            ${mixins.textOverflow()};
          }
        }
      }
    `}
`;
