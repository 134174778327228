import styled, { css } from 'styled-components';

import { colors, mixins } from '../theme';
import { Variant } from './checkbox';

export const Root = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })}
`;

export const Label = styled.div`
  cursor: pointer;
`;

export const Icon = styled.div<{ checked?: boolean; variant?: Variant }>`
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: 2px solid ${colors.background.grayStandard};
  position: relative;
  border-radius: 4px;
  margin-right: 8px;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      border-color: ${colors.background.white};
    `}

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    opacity: 0;
    ${({ checked }) =>
      checked &&
      css`
        opacity: 1;
      `}

    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: ${colors.background.grayStandard};
    border-radius: 2px;

    ${({ variant }) =>
      variant === 'accent' &&
      css`
        background: ${colors.background.yellow};
      `}

    ${({ variant }) =>
      variant === 'light' &&
      css`
        background: ${colors.background.white};
      `}
  }
`;
