import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import Stepper from '@ui-kit/stepper';

import EyePath from '../../assets/images/eye.svg';
import {
  BalanceContainer,
  Eye,
  Root,
  TextBalance,
  TextBold,
  TextSider,
} from './sidebar-payment.styles';

interface SidebarCreateArProps {}

export const SidebarPayment: FC<SidebarCreateArProps> = memo(() => {
  const { t } = useTranslation();

  const { data } = useQuery<{
    me: {
      avararUrl: string | null;
      createdAt: string | null;
      email: string | null;
      firstname: string | null;
      id: 1;
      lastname: string | null;
      nickname: string | null;
      balance: {
        current_balance: number;
      };
    };
  }>(queries.user.me);
  return (
    <Root>
      <BalanceContainer>
        <Eye src={EyePath} />
        <TextBalance>
          {t('payment.balance')} -{' '}
          {data?.me?.balance?.current_balance.toLocaleString('ru') + ' '}
          {t('payment.views')}
        </TextBalance>
      </BalanceContainer>
      <TextSider>
        При остатке в <TextBold>100 показов</TextBold>
        <br /> вам придет уведомление на почту о необходимости пополнить баланс.
      </TextSider>
      <TextSider>
        При достижении значения <br />
        <TextBold> 0 показов</TextBold> ваш AR контент будет не доступен для
        просмотра пользователями.
      </TextSider>
      <TextSider>
        <TextBold>Выставляйте счет </TextBold>для бухгалтера вашей Организации
        (предварительно укажите наименование и реквизиты в профиле пользователя)
        <br />
        <TextBold> или оплатите онлайн</TextBold>, в том числе и для Организаций
        (через Сбер Бизнес).
      </TextSider>
    </Root>
  );
});
