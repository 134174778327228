import styled, { css } from 'styled-components';

import AvatarUi from '@ui-kit/avatar';
import { animations, colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  padding: 14px 40px;
  background: ${colors.background.grayDark};
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 100;
  ${mixins.flex({
    horizontalAlignment: 'space-between',
    verticalAlignment: 'center',
  })}
  border-bottom: 1px solid ${colors.background.grayStandard}
`;

export const LogoWrapper = styled.div`
  ${mixins.flex({ verticalAlignment: 'center' })};
  width: 80px;
`;
export const Logo = styled.img`
  width: 100%;
`;

export const Eye = styled.img`
  width: 25px;
  height: 25px;
`;

export const ViewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ViewsText = styled.p`
  padding-right: 8px;
  padding-left: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.text.orange};
`;

export const Menu = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })}
`;

export const MenuItem = styled.div<{ isActive?: boolean; margin?: number }>`
  margin: ${({ margin = 32 }) => `0 ${margin}px`};
  color: ${colors.text.white};
  transition: color ease ${animations.duration};

  ${typography.regular16};
  ${mixins.flex({
    verticalAlignment: 'center',
  })};

  svg {
    cursor: pointer;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.accent.yellow};
    `};

  &:hover {
    color: ${colors.accent.yellow};
  }
`;

export const Avatar = styled(AvatarUi)`
  cursor: pointer;
  color: ${colors.text.gray};
`;
