import styled, { css } from 'styled-components';

import { Slider } from '@reach/slider';
import '@reach/slider/styles.css';
import { colors } from '@ui-kit/theme';

export const StyledSlider = styled(Slider)`
  height: 2px !important;
  border-radius: 2px;
  [data-reach-slider-range] {
    background: #fff !important;
    border-radius: 2px;
  }
  [data-reach-slider-handle] {
    width: 2px !important;
    background: #fff;
    cursor: pointer;
    //margin-left: 8px;

    &:active {
      cursor: grab;
      background: ${colors.accent.yellow};
    }

    &:after {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      position: relative;
      left: -16px;
      top: -16px;
      &:active {
        cursor: grab;
      }
    }
  }
`;

export const Container = styled.div<{ isHidden?: boolean }>`
  background: red;
  opacity: 1;

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
    `}
`;
