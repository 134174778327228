import styled from 'styled-components';

import Icons from '@ui-kit/icons';
import { colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div``;

export const StyledIcon = styled(Icons.ArrowLeft)`
  margin-right: 8px;
  color: ${colors.text.grayExtraLight};
`;

export const Item = styled.div`
  ${mixins.flex({
    horizontal: true,
  })};
  cursor: pointer;

  &:hover {
    ${StyledIcon} {
      color: ${colors.text.orange};
    }
  }
`;

export const Title = styled.div`
  ${typography.regular16};
  color: ${colors.text.white};
`;

export const SubTitle = styled.div`
  ${typography.regular12};
  color: ${colors.text.grayExtraLight};
`;

export const ItemContent = styled.div``;

export const StepperWrapper = styled.div`
  margin-top: 40px;
`;

export const ListSubLabel = styled.div`
  ${mixins.flex({ verticalAlignment: 'center' })};

  svg {
    margin-right: 6px;
  }
`;
