import React, { FC } from 'react';

const ViewsInMenu: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7333 11.6663C12.7333 13.1759 11.5095 14.3997 9.99996 14.3997C8.49038 14.3997 7.26663 13.1759 7.26663 11.6663C7.26663 10.1568 8.49038 8.93301 9.99996 8.93301C11.5095 8.93301 12.7333 10.1568 12.7333 11.6663Z"
        stroke="currentColor"
        stroke-width="1.2"
      />
      <path
        d="M17.5 11.667C17.5 11.667 16.6667 5.00033 10 5.00033C3.33333 5.00033 2.5 11.667 2.5 11.667"
        stroke="currentColor"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default <ViewsInMenu />;
