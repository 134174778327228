import styled from 'styled-components';

import Icons from '@ui-kit/icons';
import { animations, colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  padding: 32px 40px;
`;

export const Row = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    wrap: false,
  })};
  margin-bottom: 20px;
`;
export const Label = styled.div`
  width: 30%;
  margin-top: 8px;
  min-width: 30%;
`;
export const Value = styled.div`
  ${typography.regular14};
  flex: 1;
`;

export const Input = styled.input`
  ${typography.regular14};
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.background.grayExtraLight};
  background: none;
  padding: 6px 16px;
  box-sizing: border-box;
  flex: 1;
  transition: all ease ${animations.duration};
  width: 100%;

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;

export const TextArea = styled.textarea`
  ${typography.regular14};
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.background.grayExtraLight};
  background: none;
  padding: 6px 16px;
  box-sizing: border-box;
  flex: 1;
  transition: all ease ${animations.duration};
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  height: 130px;

  &:focus {
    border-color: ${colors.background.grayStandard};
  }
`;

export const ChipsWrapper = styled.div``;

export const StyledDownloadIcon = styled(Icons.Download)`
  margin-left: 16px;
  color: ${colors.background.grayDark};
  cursor: pointer;

  &:hover {
    color: ${colors.accent.orange};
  }
`;
