import React, { FC, memo, useContext } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from 'react-router-dom';

import SidebarCreateAr from '@src/components/sidebar-create-ar';
import SidebarFaq from '@src/components/sidebar-faq';
import SidebarMedia from '@src/components/sidebar-media';
import SidebarPayment from '@src/components/sidebar-payment';
import {
  AuthContext,
  CreateArProvider,
  MediaFiltersProvider,
} from '@src/context';
import { CREATE_AR_FORM_MODE } from '@src/core/types';
import { CreateAr, Faq, Home, Library, Me, Media, Payment } from '@src/pages';

import Layout from '../layout';

interface RoutesProps {}

export const Routes: FC<RoutesProps> = memo(() => {
  const { isAuthorized } = useContext(AuthContext);

  if (!isAuthorized) {
    return (
      <Router>
        <Switch>
          <Route
            path="/"
            element={
              <Layout withSidebar={false}>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/signup"
            element={
              <Layout withSidebar={false}>
                <Home type="signup" />
              </Layout>
            }
          />

          <Route
            path="/faq/:question"
            element={
              <Layout sideBar={<SidebarFaq />} withWrapper>
                <Faq />
              </Layout>
            }
          />

          <Route
            path="/library"
            element={
              <Layout withSidebar={false} withWrapper>
                <Library />
              </Layout>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/create-ar"
          element={
            <CreateArProvider>
              <Layout sideBar={<SidebarCreateAr />} withWrapper>
                <CreateAr />
              </Layout>
            </CreateArProvider>
          }
        />

        <Route
          path="/edit-ar/:id"
          element={
            <CreateArProvider mode={CREATE_AR_FORM_MODE.EDIT}>
              <Layout sideBar={<SidebarCreateAr />} withWrapper>
                <CreateAr />
              </Layout>
            </CreateArProvider>
          }
        />

        <Route
          path="/media"
          element={
            <MediaFiltersProvider>
              <Layout sideBar={<SidebarMedia />} withWrapper>
                <Media />
              </Layout>
            </MediaFiltersProvider>
          }
        />

        <Route
          path="/library"
          element={
            <Layout withSidebar={false} withWrapper>
              <Library />
            </Layout>
          }
        />

        <Route
          path="/me"
          element={
            <Layout withSidebar={false} withWrapper>
              <Me />
            </Layout>
          }
        />

        <Route
          path="/faq/:question"
          element={
            <Layout sideBar={<SidebarFaq />} withWrapper>
              <Faq />
            </Layout>
          }
        />

        <Route
          path="/payment"
          element={
            <Layout sideBar={<SidebarPayment />} withWrapper>
              <Payment />
            </Layout>
          }
        />

        <Route path="*" element={<Navigate to="/create-ar" />} />
      </Switch>
    </Router>
  );
});
