import React, { FC } from 'react';

const ArrowLeft: FC = () => {
  return (
    <path
      d="M1.625 7.5H17.375M7.75 13.625L1.625 7.5L7.75 13.625ZM1.625 7.5L7.75 1.375L1.625 7.5Z"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

export default <ArrowLeft />;
