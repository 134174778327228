type OnClose = () => void;

const createPopupManager = () => {
  let pageTop: number;

  const setPageStyle = (isOpenedPopup: boolean) => {
    const page = document.getElementById('page');

    if (!page) {
      throw new Error('Not found page element');
    }

    if (isOpenedPopup) {
      pageTop = document.documentElement.scrollTop;
    }

    Object.assign(page.style, {
      top: isOpenedPopup ? `-${pageTop}px` : 'auto',
      position: isOpenedPopup ? 'fixed' : 'static',
      width: isOpenedPopup ? '100%' : 'auto',
    });

    document.documentElement.scrollTop = isOpenedPopup ? 0 : pageTop;
  };

  const addPopup = (
    onClose: OnClose,
    isWindow: boolean,
    isCenterWindow: boolean,
    isFullScreenWindow: boolean
  ) => {
    removePopup();

    const popup = document.createElement('div');
    Object.assign(popup.style, {
      display: 'inline-block',
      textAlign: 'left',
      position: 'relative',
      margin: '20px',
      ...(isFullScreenWindow && {
        width: '100%',
        height: '100%',
        margin: 0,
      }),
    });

    const wrapper = document.createElement('div');
    wrapper.id = 'popup';
    if (isCenterWindow) {
      Object.assign(wrapper.style, {
        top: '50%',
        left: '50%',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
      });
    } else {
      Object.assign(wrapper.style, {
        height: '100%',
        top: '0',
        right: '0',
        left: '0',
        minHeight: '100%',
        position: 'absolute',
        zIndex: '400',
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
      });
    }

    if (isWindow || isFullScreenWindow) {
      const popupPlaceholder = document.createElement('div');
      Object.assign(popupPlaceholder.style, {
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: '100%',
      });

      const backdrop = document.createElement('div');
      Object.assign(backdrop.style, {
        background: 'rgba(0,0,0, 0.8)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
      });
      backdrop.addEventListener('click', onClose);

      popupPlaceholder.appendChild(popup);

      wrapper.appendChild(backdrop);
      wrapper.appendChild(popupPlaceholder);
    } else {
      wrapper.appendChild(popup);
    }

    const elementInFullscreenMode = document.fullscreenElement;

    if (elementInFullscreenMode) {
      elementInFullscreenMode.appendChild(wrapper);
    } else {
      document.body.appendChild(wrapper);
    }

    return popup;
  };

  const removePopup = () => {
    const popup = document.getElementById('popup');

    if (popup) {
      popup.parentNode?.removeChild(popup);
    }
  };

  const createPopupPlaceholder = (
    onClose: OnClose,
    isWindow = true,
    isCenterWindow = false,
    isFullScreenWindow = false
  ) => {
    setPageStyle(true);
    return addPopup(onClose, isWindow, isCenterWindow, isFullScreenWindow);
  };

  const destroyPopupPlaceholder = () => {
    setPageStyle(false);
    removePopup();
  };

  return { createPopupPlaceholder, destroyPopupPlaceholder };
};

const popupManager = createPopupManager();

export const { createPopupPlaceholder, destroyPopupPlaceholder } = popupManager;
