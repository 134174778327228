import React, { FC } from 'react';

const ByObject: FC = () => {
  return (
    <>
      <path
        d="M18.8399 20.7696C18.2789 20.7696 17.8242 20.3149 17.8242 19.7539V11.2227C17.8242 10.6618 18.2789 10.2071 18.8399 10.2071C19.4008 10.2071 19.8555 10.6618 19.8555 11.2227V19.754C19.8555 20.3149 19.4008 20.7696 18.8399 20.7696ZM21.0398 24.2331L24.1973 21.1017C25.343 19.9655 26.0001 18.3884 26.0001 16.7749V6.09375C26.0001 2.73366 23.2664 0 19.9063 0H9.2513C7.63318 0 6.10919 0.627507 4.9603 1.767L1.80275 4.8984C0.657068 6.03453 0 7.6116 0 9.2252V19.9063C0 23.2664 2.73366 26.0001 6.09375 26.0001H16.7488C18.367 26.0001 19.8909 25.3726 21.0398 24.2331ZM19.9063 2.03125C22.1464 2.03125 23.9688 3.85367 23.9688 6.09375V16.7749C23.9688 17.8506 23.5307 18.902 22.767 19.6594L19.6095 22.7908C18.8435 23.5505 17.8276 23.9688 16.7488 23.9688H6.09375C3.85367 23.9688 2.03125 22.1464 2.03125 19.9063V9.2252C2.03125 8.1495 2.46931 7.09811 3.23308 6.34067L6.39058 3.20922C7.15655 2.44959 8.17249 2.03125 9.2513 2.03125H19.9063ZM21.102 6.32328L21.5291 5.90713C21.9309 5.51573 21.9392 4.87269 21.5478 4.47095C21.1564 4.06918 20.5133 4.0608 20.1116 4.45225L19.6845 4.8684C18.8736 5.65862 17.8034 6.09375 16.6712 6.09375H6.55081C5.98988 6.09375 5.53516 6.54847 5.53516 7.1094C5.53516 7.67034 5.98988 8.12506 6.55081 8.12506H16.6711C18.336 8.12501 19.9095 7.48513 21.102 6.32328Z"
        fill="currentColor"
      />
    </>
  );
};

export default <ByObject />;
