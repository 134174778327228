import React, { FC } from 'react';

const ByImage: FC = () => {
  return (
    <>
      <path
        d="M19.9062 26H6.09375C2.73366 26 0 23.2663 0 19.9062V6.09375C0 2.73366 2.73366 0 6.09375 0H19.9062C23.2663 0 26 2.73366 26 6.09375V19.9062C26 23.2663 23.2663 26 19.9062 26ZM6.09375 2.03125C3.85369 2.03125 2.03125 3.85369 2.03125 6.09375V19.9062C2.03125 22.1463 3.85369 23.9688 6.09375 23.9688H19.9062C22.1463 23.9688 23.9688 22.1463 23.9688 19.9062V6.09375C23.9688 3.85369 22.1463 2.03125 19.9062 2.03125H6.09375ZM16.8594 9.64844C17.5605 9.64844 18.1289 9.08004 18.1289 8.37891C18.062 6.6949 15.6565 6.69536 15.5898 8.37891C15.5898 9.08004 16.1582 9.64844 16.8594 9.64844ZM21.7421 14.2153C21.3196 13.8466 20.678 13.89 20.309 14.3126L18.5905 16.2815C17.9866 16.9383 17.4193 16.9835 16.7011 16.4302C16.7011 16.4302 11.9779 11.9572 11.9542 11.9384C10.5913 10.8605 8.6642 10.9215 7.37212 12.0836C7.35109 12.1024 4.38912 15.2495 4.38912 15.2495C4.00481 15.658 4.02441 16.3008 4.43295 16.6851C4.84159 17.0695 5.48432 17.0498 5.86859 16.6412L8.75829 13.5694C9.30155 13.1037 10.0904 13.0782 10.6626 13.5074C10.6626 13.5074 15.384 17.9786 15.4076 17.9973C16.1113 18.5537 16.9219 18.8398 17.6719 18.8398C18.2743 18.8201 19.224 18.6002 20.0977 17.6433C20.1028 17.6377 20.1079 17.6321 20.1128 17.6264L21.8394 15.6484C22.2082 15.2258 22.1647 14.5842 21.7421 14.2153Z"
        fill="currentColor"
      />
    </>
  );
};

export default <ByImage />;
