import React, { FC } from 'react';

import { arrayFill } from '@src/core/utils';
import Skeleton from '@ui-kit/skeleton';

import { LoadingRoot, SkeletonCol, SkeletonRowWrapper } from './media.styles';

const SKELETON_TABLE = arrayFill({ count: 20 });
const SKELETON_INFO = arrayFill({ count: 5 });

export const TableLoading: FC = () => (
  <LoadingRoot>
    {SKELETON_TABLE.map((item, i) => (
      <SkeletonRowWrapper key={`skeleton-row-${i}`}>
        <SkeletonCol width="7%">
          <Skeleton height={32} />
        </SkeletonCol>
        <SkeletonCol width="73%">
          <Skeleton height={32} />
        </SkeletonCol>
        <SkeletonCol width="20%">
          <Skeleton height={32} />
        </SkeletonCol>
      </SkeletonRowWrapper>
    ))}
  </LoadingRoot>
);

export const InfoLoading: FC = () => {
  return (
    <>
      {SKELETON_INFO.map((item, i) => (
        <SkeletonRowWrapper key={`info-row-skeleton-${i}`}>
          <SkeletonCol width="40%">
            <Skeleton height={20} />
          </SkeletonCol>
          <SkeletonCol width="55%">
            <Skeleton height={72} />
          </SkeletonCol>
        </SkeletonRowWrapper>
      ))}
    </>
  );
};
