import styled from 'styled-components';

import { colors, mixins, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({})};
  color: ${colors.text.gray};
  ${typography.bold12};
`;

export const Item = styled.div`
  cursor: default;

  &:after {
    content: '/';
    margin: 0 8px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;
