import React, { FC, ReactElement, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import MinusPath from '../../../../assets/images/minus.svg';
import PlusPath from '../../../../assets/images/plus.svg';
import {
  BoxCount,
  Card,
  Counter,
  Description,
  Image,
  Minus,
  Plus,
  TextValue,
} from './views-card.styles';

interface ViewsCardProps {
  item: {
    views: number;
    component: ReactElement;
    description: string;

    value: number;
  };
  minus: () => void;
  plus: () => void;
}

export const ViewsCard: FC<ViewsCardProps> = memo(
  ({ item, minus, plus, ...props }) => {
    const { views, component, description, value } = item;
    const { t, i18n } = useTranslation();
    return (
      <Card>
        <Image> {component}</Image>
        <TextValue>
          {views.toLocaleString('ru')} {t('payment.views')}
        </TextValue>
        <Description>{description}</Description>
        <Counter>
          <Minus src={MinusPath} onClick={minus} />
          <BoxCount>{value}</BoxCount>
          <Plus src={PlusPath} onClick={plus} />
        </Counter>
      </Card>
    );
  }
);
