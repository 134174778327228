import React, { FC } from 'react';

const BySpace: FC = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4312 12.8333H26.8333C27.4773 12.8333 28 13.356 28 14C28 14.644 27.4773 15.1667 26.8333 15.1667H24.4312C23.891 20.0253 20.0253 23.891 15.1667 24.4312V26.8333C15.1667 27.4773 14.644 28 14 28C13.356 28 12.8333 27.4773 12.8333 26.8333V24.4312C7.97475 23.891 4.109 20.0253 3.56883 15.1667H1.16667C0.522667 15.1667 0 14.644 0 14C0 13.356 0.522667 12.8333 1.16667 12.8333H3.56883C4.109 7.97475 7.97475 4.109 12.8333 3.56883V1.16667C12.8333 0.522667 13.356 0 14 0C14.644 0 15.1667 0.522667 15.1667 1.16667V3.56883C20.0253 4.109 23.891 7.97475 24.4312 12.8333ZM5.83333 14C5.83333 18.5033 9.49667 22.1667 14 22.1667C18.5033 22.1667 22.1667 18.5033 22.1667 14C22.1667 9.49667 18.5033 5.83333 14 5.83333C9.49667 5.83333 5.83333 9.49667 5.83333 14ZM9.91661 14.0004C9.91661 11.7488 11.7489 9.91711 13.9999 9.91711C16.251 9.91711 18.0833 11.7488 18.0833 14.0004C18.0833 16.2521 16.251 18.0838 13.9999 18.0838C11.7489 18.0838 9.91661 16.2521 9.91661 14.0004ZM12.2499 14.0004C12.2499 14.9653 13.0351 15.7504 13.9999 15.7504C14.9648 15.7504 15.7499 14.9653 15.7499 14.0004C15.7499 13.0356 14.9648 12.2504 13.9999 12.2504C13.0351 12.2504 12.2499 13.0356 12.2499 14.0004Z"
        fill="currentColor"
      />
    </>
  );
};

export default <BySpace />;
