export type Alignment =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'stretch';

export interface FlexProps {
  inline?: boolean;
  wrap?: boolean;
  horizontal?: boolean;
  reverse?: boolean;
  verticalAlignment?: Alignment;
  horizontalAlignment?: Alignment;
}

export const flex = ({
  inline = false,
  wrap = true,
  horizontal = true,
  reverse = false,
  verticalAlignment,
  horizontalAlignment,
}: FlexProps) => {
  return `
    display: ${inline ? 'inline-flex' : 'flex'};
    flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
    flex-direction: ${
      horizontal
        ? `row${reverse ? '-reverse' : ''}`
        : `column${reverse ? '-reverse' : ''}`
    };

    ${
      horizontalAlignment
        ? horizontal
          ? `
          justify-content: ${horizontalAlignment};
        `
          : `
          align-items: ${horizontalAlignment};
        `
        : ''
    }

    ${
      verticalAlignment
        ? horizontal
          ? `
          align-items: ${verticalAlignment};
        `
          : `
          justify-content: ${verticalAlignment};
        `
        : ''
    }
  `;
};
