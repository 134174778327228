import styled, { css } from 'styled-components';

import { animations, colors, shape, typography } from '../theme';
import { Variant } from './button';

interface Props {
  variant?: Variant;
  disabled?: boolean;
  fullWidth?: boolean;
  disabledWhite?: boolean;
}

export const Root = styled.button<Props>`
  ${typography.medium16};
  background: ${colors.background.yellow};
  border-radius: ${shape.rounding.M};
  padding: 8px 48px;
  border: 1px solid ${colors.background.grayStandard};
  cursor: pointer;
  transition: all ease ${animations.duration};
  &:hover {
    background: ${colors.background.grayExtraDark};
    color: ${colors.text.white};
  }

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      background: ${colors.background.grayStandard};
      color: ${colors.background.white};
    `};

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background: none;
      border: 1px solid ${colors.background.grayStandard};
      color: ${colors.background.grayStandard};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${colors.background.grayExtraLight};
      border-color: ${colors.background.grayExtraLight};
      color: ${colors.background.grayStandard};
      cursor: default;

      &:hover {
        background: ${colors.background.grayExtraLight};
        border-color: ${colors.background.grayExtraLight};
        color: ${colors.background.grayStandard};
      }
    `};

  ${({ disabledWhite }) =>
    disabledWhite &&
    css`
      color: ${colors.background.grayLight};
      &:hover {
        color: ${colors.background.grayLight};
      }
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
