export const breakpoints = {
  PhoneSmall: 320,
  PhoneMedium: 374,
  Phone: 599,
  Tablet: 1023,
  Laptop: 1279,
  LaptopLarge: 1324,
  LargeScreen: 1872,
};

export type BreakpointsKeys = keyof typeof breakpoints;

export const media = {
  /**
   * 1872 ~
   */
  forLargeScreen: `@media (min-width: ${breakpoints.LargeScreen}px)`,
  /**
   * ~ 1324
   */
  forLaptopLarge: `@media (max-width: ${breakpoints.LaptopLarge}px)`,
  /**
   * ~ 1279
   */
  forLaptop: `@media (max-width: ${breakpoints.Laptop}px)`,
  /**
   * ~ 1023
   */
  forTablet: `@media (max-width: ${breakpoints.Tablet}px)`,
  /**
   * ~ 599
   */
  forPhone: `@media (max-width: ${breakpoints.Phone}px)`,
  /**
   * ~ 374
   */
  forPhoneMedium: `@media (max-width: ${breakpoints.PhoneMedium}px)`,
  /**
   * ~ 320
   */
  forPhoneSmall: `@media (max-width: ${breakpoints.PhoneSmall}px)`,
};
