import React, { FC } from 'react';

const StepEmpty: FC = () => {
  return (
    <>
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="8"
        stroke="currentColor"
        strokeWidth="2"
      />
    </>
  );
};

export default <StepEmpty />;
