import styled from 'styled-components';

export const Root = styled.div``;

export const ActionsWrapper = styled.div`
  & > * {
    &:first-child {
      margin-right: 16px;
    }
  }
`;
