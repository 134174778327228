import QRCode from 'qrcode.react';
import React, { FC, PropsWithChildren, useCallback } from 'react';

interface QrCodeProps {
  value: string;
  size?: number; // defaults to 128
  bgColor?: string; // defaults to '#FFFFFF'
  fgColor?: string; // defaults to '#000000'
  level?: 'L' | 'M' | 'Q' | 'H'; // defaults to 'L'
  downloadFileName?: string;
}

export const QrCode: FC<PropsWithChildren<QrCodeProps>> = ({
  children,
  downloadFileName,
  ...props
}) => {
  const handleClick = useCallback(
    (event: any) => {
      const qrCodeURL = event.target
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

      let aEl = document.createElement('a');
      aEl.href = qrCodeURL;
      aEl.download = downloadFileName || 'QR_Code.png';
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    },
    [downloadFileName]
  );

  return (
    <QRCode {...props} onClick={handleClick} style={{ cursor: 'pointer' }} />
  );
};
